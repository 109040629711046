"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpeechBubbles = void 0;
const mesh_1 = require("@babylonjs/core/Meshes/mesh");
const dynamicTexture_1 = require("@babylonjs/core/Materials/Textures/dynamicTexture");
const standardMaterial_1 = require("@babylonjs/core/Materials/standardMaterial");
const meshBuilder_1 = require("@babylonjs/core/Meshes/meshBuilder");
class SpeechBubbles {
    constructor(parentMesh) {
        this.geoWidth = 8;
        this.geoHeight = 4;
        this.width = 128;
        this.height = this.width / (this.geoWidth / this.geoWidth) / 2;
        this.text = '';
        this.timerHandler = null;
        this.mesh = meshBuilder_1.MeshBuilder.CreatePlane('speechBubble', {
            width: this.geoWidth, height: this.geoHeight,
        }, parentMesh.getScene());
        this.texture = new dynamicTexture_1.DynamicTexture('speechBubbleTexture', {
            width: this.width,
            height: this.height,
        }, parentMesh.getScene(), true);
        const mat = new standardMaterial_1.StandardMaterial('speechBubbleMaterial', parentMesh.getScene());
        mat.diffuseTexture = this.texture;
        mat.diffuseTexture.hasAlpha = true;
        mat.specularPower = 0;
        this.texture.updateSamplingMode(8);
        this.mesh.material = mat;
        this.mesh.position.y += parentMesh.getBoundingInfo().maximum.y
            + this.geoHeight;
        this.mesh.parent = parentMesh;
        this.mesh.billboardMode = mesh_1.Mesh.BILLBOARDMODE_ALL;
        this.mesh.isVisible = false;
    }
    setText(text, timeout, setVisible = true) {
        if (this.timerHandler !== null) {
            clearTimeout(this.timerHandler);
        }
        if (setVisible) {
            this.mesh.isVisible = true;
        }
        if (this.text !== text) {
            this.text = text;
            const ctx = this.texture.getContext();
            ctx.clearRect(0, 0, this.width, this.height);
            const size = 12;
            const fontType = 'Code';
            ctx.font = `${size}px ${fontType}`;
            const textWidth = ctx.measureText(text).width;
            const ratio = textWidth / size;
            const fontSize = Math.floor(this.width / (ratio * 1));
            const font = `${fontSize}px ${fontType}`;
            this.texture.drawText(text, null, null, font, '#fff', 'rgba(0, 0, 0, 0)', true);
        }
        if (timeout !== undefined && timeout !== null) {
            this.timerHandler = setTimeout(() => {
                this.mesh.isVisible = false;
                this.timerHandler = null;
            }, timeout);
        }
    }
}
exports.SpeechBubbles = SpeechBubbles;
