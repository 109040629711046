"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoalsManager = void 0;
const math_scalar_1 = require("@babylonjs/core/Maths/math.scalar");
class GoalsManager {
    constructor(comms, car, audioEngine) {
        this.comms = comms;
        this.car = car;
        this.audioEngine = audioEngine;
        this.goals = new Set();
        this.completedGoals = new Set();
        this.currentGoal = null;
        this.goalCount = 0;
        this.lastRightPlaceMessageAt = 0;
        this.rightPlaceTimeOut = 10 * 1000;
        this.lastWrongPlaceMessageAt = 0;
        this.wrongPlaceTimeout = 10 * 1000;
    }
    addGoal(goal) {
        this.goals.add(goal);
        this.goalCount = this.goals.size;
    }
    updateGoal(goal) {
        const now = Date.now();
        if (goal.getIsCurrent()) {
            if (goal.load.isLoaded()) {
                this.markCompleted(goal);
                this.playDelivered();
                if (this.currentGoal !== null) {
                    this.currentGoal.setIsCurrent(false);
                }
                if (this.timerHandle !== null) {
                    clearTimeout(this.timerHandle);
                    this.timerHandle = null;
                }
                this.timerHandle = setTimeout(() => {
                    this.timerHandle = null;
                    this.nextGoal();
                    this.checkGameOver();
                }, 5000);
            }
            else {
                if (this.lastRightPlaceMessageAt < now - this.rightPlaceTimeOut) {
                    this.audioEngine.playRightPlaceNoPackage();
                    this.lastRightPlaceMessageAt = now;
                }
                this.comms.setMessage('Need to pick up the package');
            }
        }
        else if (goal.getCompletedAt() < Date.now() - 5000) {
            if (this.lastWrongPlaceMessageAt < now - this.wrongPlaceTimeout) {
                this.audioEngine.playWrongPlace();
                this.lastWrongPlaceMessageAt = now;
            }
            this.comms.setMessage('This is the wrong place');
        }
    }
    getCurrentGoal() {
        return this.currentGoal;
    }
    getProgress() {
        const numberOfGoals = this.goalCount;
        const completedGoals = this.completedGoals.size;
        if (numberOfGoals === 0) {
            return 0;
        }
        return (completedGoals + 1) / numberOfGoals;
    }
    markCompleted(goal) {
        goal.markCompleted();
        this.goals.delete(goal);
        this.completedGoals.add(goal);
    }
    nextGoal() {
        const oldGoal = this.currentGoal;
        this.currentGoal = null;
        const min = 0;
        const max = this.goals.size - 1;
        if (max < 0) {
            return;
        }
        let goal;
        const array = Array.from(this.goals);
        do {
            const index = Math.round(math_scalar_1.Scalar.RandomRange(min, max));
            goal = array[index];
        } while (array.length > 1 && goal.getName() === 'Corner block');
        goal.setIsCurrent(true);
        if (oldGoal === null) {
            this.audioEngine.playPickup();
        }
        else {
            this.audioEngine.playNewDelivery();
        }
        this.currentGoal = goal;
    }
    playDelivered() {
        this.audioEngine.playFanfare();
        this.audioEngine.playDelivered();
        this.comms.setMessage('We delivered');
        this.car.resetFlip(this.car.transformNode.rotationQuaternion);
    }
    checkGameOver() {
        if (!this.allComplete()) {
            return false;
        }
        this.comms.setMessage('Game over!\nThanks for playing');
        this.comms.setGameOver();
        this.audioEngine.playGameOver();
        return true;
    }
    allComplete() {
        return this.completedGoals.size === this.goalCount;
    }
    reset() {
        this.currentGoal = null;
        this.completedGoals.forEach((g) => {
            g.setIsCurrent(false);
            this.goals.add(g);
        });
        this.completedGoals.clear();
        this.nextGoal();
    }
}
exports.GoalsManager = GoalsManager;
