"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HighlightCone = void 0;
const standardMaterial_1 = require("@babylonjs/core/Materials/standardMaterial");
const meshBuilder_1 = require("@babylonjs/core/Meshes/meshBuilder");
const math_color_1 = require("@babylonjs/core/Maths/math.color");
class HighlightCone {
    constructor(parentMesh, color = new math_color_1.Color3(255, 0, 128)) {
        this.height = 10;
        this.mesh = meshBuilder_1.MeshBuilder.CreateCylinder('highlightCone', {
            diameterTop: 0,
            diameterBottom: this.height / 2,
            tessellation: 4,
            height: this.height,
        }, parentMesh.getScene());
        const mat = new standardMaterial_1.StandardMaterial('highlightConeMaterial', parentMesh.getScene());
        mat.emissiveColor = color;
        mat.freeze();
        this.mesh.rotation.x += Math.PI;
        this.mesh.material = mat;
        this.mesh.position.y += parentMesh.getBoundingInfo().maximum.y
            + this.height / 2;
        this.mesh.parent = parentMesh;
    }
}
exports.HighlightCone = HighlightCone;
