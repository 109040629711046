"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.playMusic = exports.showUi = exports.enableAi = void 0;
let settings = null;
const get = () => {
    if (settings === null) {
        const { searchParams } = new URL(window.location.toString());
        const getBool = (name) => {
            const bool = searchParams.get(name);
            return bool === null || bool === 'true';
        };
        settings = {
            ai: getBool('ai'),
            music: getBool('music'),
            ui: getBool('ui'),
        };
    }
    return settings;
};
const enableAi = () => get().ai;
exports.enableAi = enableAi;
const showUi = () => get().ui;
exports.showUi = showUi;
const playMusic = () => get().music;
exports.playMusic = playMusic;
