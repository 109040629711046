"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarAi = void 0;
const math_scalar_1 = require("@babylonjs/core/Maths/math.scalar");
const standardMaterial_1 = require("@babylonjs/core/Materials/standardMaterial");
const meshBuilder_1 = require("@babylonjs/core/Meshes/meshBuilder");
const math_color_1 = require("@babylonjs/core/Maths/math.color");
const math_1 = require("@babylonjs/core/Maths/math");
const CarLeftOrRight_1 = require("./CarLeftOrRight");
const randomFromArray_1 = require("./randomFromArray");
const messages = require("./messages");
class CarAi {
    constructor(car, startPosition, startRotation, leftRight, load, audioEngine, screamFloor, killFloor, randomDriveTestInterval, randomDriveProbability, goalsManager) {
        this.car = car;
        this.startPosition = startPosition;
        this.startRotation = startRotation;
        this.leftRight = leftRight;
        this.load = load;
        this.audioEngine = audioEngine;
        this.screamFloor = screamFloor;
        this.killFloor = killFloor;
        this.randomDriveTestInterval = randomDriveTestInterval;
        this.randomDriveProbability = randomDriveProbability;
        this.goalsManager = goalsManager;
        this.lastMessageAt = 0;
        this.speekInterval = 10 * 1000;
        this.isUpsideDown = false;
        this.isRightwayUp = true;
        this.minStuckSpeed = 1;
        this.isStuck = false;
        this.baseMinStuckTimeMin = 2 * 1000;
        this.baseMinStuckTimeMax = 4 * 1000;
        this.baseReverseTimeMin = 8 * 1000;
        this.baseReverseTimeMax = 9 * 1000;
        this.lastStuckAt = Date.now();
        this.lastStuckChange = Date.now();
        this.randomDrive = true;
        this.lastRandomDriveTest = Date.now();
        this.isScreaming = false;
        this.isReversing = false;
        this.wahSound = null;
        this.minDownTime = 20 * 1000;
        this.isEnabled = false;
        this.navigationPlugin = null;
        this.minStuckTime = math_scalar_1.Scalar.RandomRange(this.baseMinStuckTimeMin, this.baseMinStuckTimeMax);
        this.reverseTime = math_scalar_1.Scalar.RandomRange(this.baseReverseTimeMin, this.baseReverseTimeMax);
        const height = 2;
        this.destination = meshBuilder_1.MeshBuilder.CreateCylinder('destination', {
            diameterTop: 0,
            diameterBottom: height / 2,
            tessellation: 4,
            height,
        }, this.car.mesh.getScene());
        const mat = new standardMaterial_1.StandardMaterial('destinationMaterial', this.car.mesh.getScene());
        mat.emissiveColor = new math_color_1.Color3(0, 255, 255);
        mat.freeze();
        this.destination.rotation.x += Math.PI;
        this.destination.material = mat;
        this.destination.isVisible = false;
    }
    setRandomDrive(randomDrive) {
        this.randomDrive = randomDrive;
        if (!randomDrive) {
            this.car.speechBubbles.setText('DELIVERY!', this.randomDriveTestInterval);
        }
    }
    setIsEnabled(isEnabled) {
        this.isEnabled = isEnabled;
    }
    update() {
        if (!this.isEnabled) {
            return;
        }
        const now = Date.now();
        this.leftRight.update();
        const lastRightwayUp = this.isRightwayUp;
        if (this.car.lastUprightChange < now - 1500) {
            this.isUpsideDown = this.car.isUpsideDown;
            this.isRightwayUp = !this.car.isUpsideDown;
        }
        const flippedUpright = this.isRightwayUp !== lastRightwayUp;
        const shouldReverse = this.isStuck
            && (this.lastStuckAt < now - this.reverseTime);
        const reverseChanged = shouldReverse !== this.isReversing;
        this.isReversing = shouldReverse;
        if (this.isReversing && reverseChanged) {
            // Change destination or we will scrash again
            this.updateRandomDestination();
        }
        this.car.actions.accelerate = !shouldReverse && Math.random() > 0.03;
        this.car.actions.brake = shouldReverse || Math.random() < 0.1;
        const hasPickedUpLoad = (this.load.isOwnerCar(this.car) && this.load.isPickedUp());
        if (this.randomDrive || hasPickedUpLoad) {
            if (Math.abs(math_1.Vector3.Distance(this.destination.position, this.car.transformNode.position)) < 10 || Math.random() > 0.999) {
                this.updateRandomDestination();
            }
            this.leftRight.setTarget(this.destination);
        }
        else {
            this.leftRight.setTarget(this.load.getMesh());
        }
        const direction = this.leftRight.getDirection();
        this.car.actions.left = direction === CarLeftOrRight_1.Direction.Left;
        this.car.actions.right = direction === CarLeftOrRight_1.Direction.Right;
        if (hasPickedUpLoad) {
            this.car.setMaxSpeedRatio(0.5);
        }
        else {
            this.car.setMaxSpeedRatio(1);
        }
        const speed = this.car.vehicle.getCurrentSpeedKmHour();
        const stuck = Math.abs(speed) < this.minStuckSpeed;
        const lastIsStuck = this.isStuck;
        if (this.lastStuckChange < now - this.minStuckTime) {
            this.lastStuckChange = now;
            this.isStuck = stuck;
        }
        const unstuck = lastIsStuck && !this.isStuck;
        if (!lastIsStuck && this.isStuck) {
            this.lastStuckAt = now;
        }
        if (this.randomDriveTestInterval < now - this.lastRandomDriveTest) {
            this.lastRandomDriveTest = now;
            const thresh = ((this.randomDriveProbability * this.goalsManager.getProgress()));
            this.setRandomDrive(Math.random() > thresh);
        }
        if (this.car.lastUprightChange < now - this.minDownTime) {
            if (this.isUpsideDown) {
                this.reset();
            }
        }
        this.trySpeek(now, flippedUpright, unstuck);
        if (!this.isScreaming
            && this.car.transformNode.position.y < this.screamFloor) {
            this.isScreaming = true;
            this.car.speechBubbles.setText('Aaaahhhh', 2000);
            this.wahSound = this.audioEngine.playWah(this.car.transformNode);
        }
        if (this.car.transformNode.position.y < this.killFloor) {
            this.reset();
        }
    }
    reset() {
        this.isScreaming = false;
        this.car.speechBubbles.setText('');
        this.car.reset(this.startPosition, this.startRotation);
        if (this.wahSound !== null) {
            if (this.wahSound.isPlaying) {
                this.wahSound.stop();
            }
            this.wahSound.detachFromMesh();
            this.wahSound = null;
        }
    }
    setNavigationSystem(navigationPlugin) {
        this.navigationPlugin = navigationPlugin;
        this.updateRandomDestination();
    }
    showNavDestination(showNavDestination) {
        this.destination.isVisible = showNavDestination;
    }
    updateRandomDestination() {
        if (this.navigationPlugin !== null) {
            this.destination.unfreezeWorldMatrix();
            this.destination.position.copyFrom(this.navigationPlugin.getRandomPointAround(this.car.transformNode.position, 200));
            this.destination.freezeWorldMatrix();
        }
    }
    trySpeek(now, flippedUpright, unstuck) {
        if (this.lastMessageAt > now - this.speekInterval
            && (!flippedUpright && !unstuck)) {
            return;
        }
        let message = null;
        let timeout = null;
        if (flippedUpright) {
            message = (0, randomFromArray_1.randomFromArray)(messages.CAR_FLIPPED_UPRIGHT_MESSAGES);
            timeout = 4000;
        }
        else if (unstuck) {
            message = (0, randomFromArray_1.randomFromArray)(messages.CAR_UNSTUCK_MESSAGES);
            timeout = 4000;
        }
        else if (this.isUpsideDown) {
            message = (0, randomFromArray_1.randomFromArray)(messages.CAR_UPSIDE_DOWN_MESSAGES);
        }
        else if (this.isStuck) {
            message = (0, randomFromArray_1.randomFromArray)(messages.CAR_STUCK_MESSAGES);
        }
        else if (Math.random() < 0.0005) {
            message = (0, randomFromArray_1.randomFromArray)(messages.CAR_RANDOM_DRIVING_MESSAGES);
            timeout = 3000;
        }
        if (message === null) {
            return;
        }
        this.car.speechBubbles.setText(message, timeout);
        this.lastMessageAt = now;
    }
}
exports.CarAi = CarAi;
