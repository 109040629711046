"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
require("@babylonjs/core/Audio/audioSceneComponent");
require("@babylonjs/core/Collisions/collisionCoordinator");
require("@babylonjs/core/Lights/Shadows/shadowGeneratorSceneComponent");
require("@babylonjs/core/Loading/loadingScreen");
require("@babylonjs/core/Materials/standardMaterial");
require("@babylonjs/core/Physics/physicsEngineComponent");
require("@babylonjs/loaders/glTF/2.0/glTFLoader");
const FontFaceObserver = require("fontfaceobserver");
const assetsManager_1 = require("@babylonjs/core/Misc/assetsManager");
const engine_1 = require("@babylonjs/core/Engines/engine");
const scene_1 = require("@babylonjs/core/scene");
const audio = require("./audio");
const Code_ttf_1 = require("./assets/fonts/Code.ttf");
const world_glb_1 = require("./assets/glb/world.glb");
const world_navmesh_1 = require("./assets/navmesh/world.navmesh");
const game_1 = require("./game");
require("./style.sass");
const promisfyBinary = (task) => (new Promise((resolve, reject) => {
    task.onSuccess = () => {
        resolve(new Uint8Array(task.data));
    };
    task.onError = (_task, _message, exception) => {
        reject(exception);
    };
}));
const runGame = (touchEnabled, scaleFactor) => __awaiter(void 0, void 0, void 0, function* () {
    const element = document.createElement('canvas');
    element.id = 'canvas';
    document.body.append(element);
    const engine = new engine_1.Engine(element, true);
    engine.setHardwareScalingLevel(scaleFactor);
    window.addEventListener('resize', () => { engine.resize(); });
    const scene = new scene_1.Scene(engine);
    const assetsManager = new assetsManager_1.AssetsManager(scene);
    assetsManager.addBinaryFileTask('font task', Code_ttf_1.default);
    const meshTask = assetsManager.addBinaryFileTask('navmesh task', world_navmesh_1.default);
    assetsManager.addMeshTask('world task', '', './', world_glb_1.default);
    const font = new FontFaceObserver('Code');
    const [audioEngine, navmesh] = yield Promise.all([
        audio.load(assetsManager, scene),
        promisfyBinary(meshTask),
        assetsManager.loadAsync(),
        font.load(),
    ]);
    // XXX: if touch then assume they can't handle lots of cars
    const numberOfAiCars = touchEnabled ? 10 : 20;
    new game_1.Game(touchEnabled, scaleFactor, numberOfAiCars, navmesh, audioEngine, scene).run();
});
const main = () => {
    const element = document.getElementById('play');
    if (!(element instanceof HTMLButtonElement)) {
        throw new Error("element with id 'play' is not an instance of HTMLButtonElement");
    }
    let handleClick;
    let handleTouch;
    let scaleFactor = 1;
    let touchEnabled = false;
    const removeElementAndRunGame = () => {
        element.removeEventListener('click', handleClick, false);
        element.removeEventListener('touchstart', handleTouch, false);
        element.remove();
        document.getElementById('container').remove();
        runGame(touchEnabled, scaleFactor);
    };
    handleClick = (e) => {
        e.preventDefault();
        removeElementAndRunGame();
    };
    handleTouch = () => {
        touchEnabled = true;
        scaleFactor = 3;
    };
    element.addEventListener('click', handleClick, false);
    element.addEventListener('touchstart', handleTouch, false);
};
window.addEventListener('load', main);
