"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayerCar = void 0;
const Car_1 = require("./Car");
class PlayerCar extends Car_1.Car {
    updateAudio() {
        const { audioEngine } = this;
        const speed = this.vehicle.getCurrentSpeedKmHour();
        audioEngine.setPlayerEngineSpeed(speed);
        audioEngine.setPlayerBraking(Math.abs(speed) > 0.2
            && ((speed > 0 && this.actions.brake)
                || (speed < 0 && this.actions.accelerate)));
    }
}
exports.PlayerCar = PlayerCar;
