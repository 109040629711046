"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CAR_UNSTUCK_MESSAGES = exports.CAR_STUCK_MESSAGES = exports.CAR_FLIPPED_UPRIGHT_MESSAGES = exports.CAR_RANDOM_DRIVING_MESSAGES = exports.CAR_UPSIDE_DOWN_MESSAGES = exports.PLAYER_CRASH_DOWN_MESSAGES = exports.PLAYER_CRASH_MESSAGES = void 0;
exports.PLAYER_CRASH_MESSAGES = [
    "Hey! I'm driving here!",
    'One of those days',
    'Probably an accident',
    'Felt that deep inside',
    'Excuse me!',
    'Can buff that out',
    'Spilt my coffee',
    'What is it with this town?',
    'Such disrespect for vehicles',
    'This is a difficult delivery',
    "They won't believe me when I tell 'em",
    'Watch it buddy!',
    'What driving school did YOU attend!',
];
exports.PLAYER_CRASH_DOWN_MESSAGES = [
    'Wake up!',
    "Can't park here pal",
    'Excuse me',
    'Snoozing time is over',
    'Need assistance?',
    'Hope that helps',
    "They're having a bad day",
    'Sorry to disturb you',
    "Here's the road side recovery",
];
exports.CAR_UPSIDE_DOWN_MESSAGES = [
    'Very comfy',
    'Having a snack',
    'Zzzz',
    'Good times',
];
exports.CAR_RANDOM_DRIVING_MESSAGES = [
    'Lovely',
    'Great fun',
    'Im lost',
];
exports.CAR_FLIPPED_UPRIGHT_MESSAGES = [
    "I'm free",
    'Amazing',
    'Back in action',
    'Just resting',
];
exports.CAR_STUCK_MESSAGES = [
    "I'm not stuck",
    'Move it!',
    'Outta the way!',
    'Who are you?',
    'Full speed',
    'Who put this here?',
];
exports.CAR_UNSTUCK_MESSAGES = [
    'Traffic huh',
    'Stupid sat nav',
    'On the road',
];
