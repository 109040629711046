"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Comms = void 0;
const moment = require("moment");
require("pepjs");
const button_1 = require("@babylonjs/gui/2D/controls/button");
const grid_1 = require("@babylonjs/gui/2D/controls/grid");
const image_1 = require("@babylonjs/gui/2D/controls/image");
const rectangle_1 = require("@babylonjs/gui/2D/controls/rectangle");
const textBlock_1 = require("@babylonjs/gui/2D/controls/textBlock");
const trucker_png_1 = require("./assets/textures/trucker.png");
const objectives_1 = require("./objectives");
const MINUTE = 60 * 1000;
const GRADES = [
    {
        grade: 'S',
        time: 2.5 * MINUTE,
    },
    {
        grade: 'A',
        time: 3 * MINUTE,
    },
    {
        grade: 'B',
        time: 4 * MINUTE,
    },
    {
        grade: 'C',
        time: 6 * MINUTE,
    },
    {
        grade: 'D',
        time: 8 * MINUTE,
    },
    {
        grade: 'E',
        time: 10 * MINUTE,
    },
    {
        grade: 'F',
        time: 12 * MINUTE,
    },
];
const STUCK_KEYS = 'Stuck?\nPress\nR\nto flip';
const STUCK_TOUCH = 'Stuck?\nTap here\nto flip';
const TOUCH_UP_COLOR = 'rgba(255, 255, 255, 0.2';
const TOUCH_DOWN_COLOR = 'rgba(255, 255, 255, 0.4';
const createTouchControl = (text) => {
    const button = button_1.Button.CreateSimpleButton(text, text);
    button.background = TOUCH_UP_COLOR;
    button.color = 'rgba(255, 255, 255, 0.5)';
    button.fontFamily = 'Code';
    return button;
};
class Comms {
    constructor(scaling, car, audioEngine, grid, touchGrid) {
        this.scaling = scaling;
        this.car = car;
        this.audioEngine = audioEngine;
        this.grid = grid;
        this.touchGrid = touchGrid;
        this.lastMessagePriority = null;
        this.timerHandler = null;
        this.objective = null;
        this.targetName = null;
        this.startTime = Date.now();
        this.endTime = null;
        this.touchControls = [
            {
                minX: 0,
                maxX: 1 / 3,
                minY: 3 / 6,
                maxY: 2 / 3,
                action: 'brake',
                identifier: null,
            },
            {
                minX: 0,
                maxX: 1 / 3,
                minY: 2 / 3,
                maxY: 1,
                action: 'accelerate',
                identifier: null,
            },
            {
                minX: 1 / 3,
                maxX: 2 / 3,
                minY: 2 / 3,
                maxY: 1,
                action: 'left',
                identifier: null,
            },
            {
                minX: 2 / 3,
                maxX: 1,
                minY: 2 / 3,
                maxY: 1,
                action: 'right',
                identifier: null,
            },
            {
                minX: 2 / 3,
                maxX: 1,
                minY: 3 / 6,
                maxY: 2 / 3,
                action: 'horn',
                identifier: null,
            },
        ];
        this.onTouchStart = (e) => {
            e.preventDefault();
            for (let i = 0; i < e.touches.length; i += 1) {
                const { pageX, pageY, identifier } = e.touches[i];
                this.setCarActionForTouch(pageX, pageY, true, identifier);
            }
        };
        this.onTouchMove = (e) => {
            e.preventDefault();
            for (let i = 0; i < e.changedTouches.length; i += 1) {
                const { pageX, pageY, identifier } = e.changedTouches[i];
                this.setCarActionForTouch(pageX, pageY, true, identifier);
            }
        };
        this.onTouchEnd = (e) => {
            e.preventDefault();
            for (let i = 0; i < e.changedTouches.length; i += 1) {
                const { pageX, pageY, identifier } = e.changedTouches[i];
                this.setCarActionForTouch(pageX, pageY, false, identifier);
            }
        };
        const buttonGrid = new grid_1.Grid();
        buttonGrid.addRowDefinition(1);
        buttonGrid.addColumnDefinition(1 / 3);
        buttonGrid.addColumnDefinition(1 / 3);
        buttonGrid.addColumnDefinition(1 / 3);
        grid.addControl(buttonGrid, 1, 0);
        this.restartButton = button_1.Button.CreateSimpleButton('restart', 'Restart');
        this.restartButton.background = 'rgba(255, 255, 255, 0.5';
        this.restartButton.fontFamily = 'Code';
        this.restartButton.heightInPixels = 200;
        this.restartButton.isVisible = false;
        buttonGrid.addControl(this.restartButton, 0, 1);
        this.touchGrid.addColumnDefinition(1 / 3);
        this.touchGrid.addColumnDefinition(1 / 3);
        this.touchGrid.addColumnDefinition(1 / 3);
        this.touchGrid.addRowDefinition(1 / 3);
        this.touchGrid.addRowDefinition(1 / 3);
        this.touchGrid.addRowDefinition(1 / 3);
        this.touchGrid.isVisible = false;
        this.touchGrid.addControl(this.restartButton, 1, 1);
        const touchInfoAlertGrid = new grid_1.Grid();
        touchInfoAlertGrid.addRowDefinition(1 / 2);
        touchInfoAlertGrid.addRowDefinition(1 / 2);
        this.touchGrid.addControl(touchInfoAlertGrid, 0, 2);
        const touchInfoGrid = new grid_1.Grid();
        touchInfoGrid.addRowDefinition(1 / 2);
        touchInfoGrid.addRowDefinition(1 / 2);
        touchInfoAlertGrid.addControl(touchInfoGrid, 0, 0);
        this.accelerate = createTouchControl('Forward');
        this.brake = createTouchControl('Back');
        this.left = createTouchControl('Left');
        this.right = createTouchControl('Right');
        this.horn = createTouchControl('Horn');
        const brakeGrid = new grid_1.Grid();
        brakeGrid.addRowDefinition(1 / 2);
        brakeGrid.addRowDefinition(1 / 2);
        brakeGrid.addControl(this.brake, 1, 0);
        this.touchGrid.addControl(this.accelerate, 2, 0);
        this.touchGrid.addControl(brakeGrid, 1, 0);
        this.touchGrid.addControl(this.left, 2, 1);
        this.touchGrid.addControl(this.right, 2, 2);
        this.startTime = Date.now();
        this.alertGrid = new grid_1.Grid();
        this.alertGrid.addRowDefinition(1);
        this.alertGrid.addColumnDefinition(1 / 3);
        this.alertGrid.addColumnDefinition(1 / 3);
        this.alertGrid.addColumnDefinition(1 / 3);
        this.grid.addControl(this.alertGrid, 0, 0);
        this.timerBlock = new textBlock_1.TextBlock('time');
        this.timerBlock.textWrapping = true;
        this.timerBlock.color = 'rgba(255, 255, 255)';
        this.timerBlock.text = '00:00.000s';
        this.timerBlock.fontFamily = 'Code';
        this.timerBlock.isVisible = true;
        this.alertGrid.addControl(this.timerBlock, 0, 0);
        touchInfoGrid.addControl(this.timerBlock, 0, 0);
        this.alertButton = button_1.Button.CreateSimpleButton('alertButton', '');
        this.alertButton.color = 'rgba(255, 0, 100)';
        this.alertButton.background = 'rgba(255, 0, 0, 0.2)';
        this.alertBlock = new textBlock_1.TextBlock('alert');
        this.alertBlock.textWrapping = true;
        this.alertBlock.color = 'rgba(255, 0, 100)';
        this.alertBlock.text = STUCK_KEYS;
        this.alertBlock.fontFamily = 'Code';
        this.alertBlock.isVisible = true;
        this.alertButton.isVisible = false;
        this.alertButton.addControl(this.alertBlock);
        this.alertButton.onPointerDownObservable.add(() => {
            this.car.resetFlipCurrent();
        });
        this.alertGrid.addControl(this.alertButton, 0, 1);
        const touchSideGrid = new grid_1.Grid();
        touchSideGrid.addRowDefinition(1 / 2);
        touchSideGrid.addRowDefinition(1 / 2);
        this.touchGrid.addControl(touchSideGrid, 1, 2);
        touchSideGrid.addControl(this.alertButton, 0, 0);
        touchSideGrid.addControl(this.horn, 1, 0);
        this.packageBlock = new textBlock_1.TextBlock('package');
        this.packageBlock.textWrapping = true;
        this.packageBlock.color = 'rgba(0, 255, 100)';
        this.packageBlock.text = 'Pickup\nThe delivery';
        this.packageBlock.fontFamily = 'Code';
        this.packageBlock.isVisible = false;
        this.alertGrid.addControl(this.packageBlock, 0, 2);
        touchInfoGrid.addControl(this.packageBlock, 1, 0);
        this.commsGrid = new grid_1.Grid();
        this.commsGrid.addRowDefinition(1);
        this.commsGrid.addColumnDefinition(1 / 3);
        this.commsGrid.addColumnDefinition(1 / 3);
        this.commsGrid.addColumnDefinition(1 / 3);
        this.grid.addControl(this.commsGrid, 2, 0);
        this.imageBlock = new image_1.Image('trucker', trucker_png_1.default);
        this.imageBlock.sourceWidth = 32;
        this.imageBlock.sourceHeight = 32;
        this.imageBlock.sourceTop = 32;
        this.imageBlock.stretch = image_1.Image.STRETCH_UNIFORM;
        this.commsGrid.addControl(this.imageBlock, 0, 0);
        const touchImageGrid = new grid_1.Grid();
        touchImageGrid.addRowDefinition(1 / 2);
        touchImageGrid.addRowDefinition(1 / 2);
        this.touchGrid.addControl(touchImageGrid, 0, 0);
        touchImageGrid.addControl(this.imageBlock, 0, 0);
        this.textRec = new rectangle_1.Rectangle();
        this.textRec.background = 'rgba(0, 128, 255, 0.75)';
        this.textRec.thickness = 5;
        this.textRec.cornerRadius = 10;
        this.commsGrid.addControl(this.textRec, 0, 1);
        const touchTextGrid = new grid_1.Grid();
        touchTextGrid.addRowDefinition(1 / 2);
        touchTextGrid.addRowDefinition(1 / 2);
        this.touchGrid.addControl(touchTextGrid, 0, 1);
        touchTextGrid.addControl(this.textRec, 0, 0);
        this.textBlock = new textBlock_1.TextBlock('message');
        this.textBlock.textWrapping = true;
        this.textBlock.color = 'white';
        this.textBlock.fontFamily = 'Code';
        this.textRec.addControl(this.textBlock);
        const setFontSize = () => {
            this.textRec.thickness = window.innerWidth / 128 / this.scaling;
            this.textRec.cornerRadius = window.innerWidth / 64 / this.scaling;
            this.textBlock.fontSize = window.innerWidth / 32 / this.scaling;
            this.alertBlock.fontSize = window.innerWidth / 24 / this.scaling;
            this.packageBlock.fontSize = window.innerWidth / 24 / this.scaling;
            this.timerBlock.fontSize = window.innerWidth / 32 / this.scaling;
            this.restartButton.fontSize = window.innerWidth / 28 / this.scaling;
        };
        window.addEventListener('resize', setFontSize);
        setFontSize();
        const messages = [
            {
                key: 'Welcome to Delivery Derby',
                touch: null,
            },
            {
                key: 'WASD to drive',
                touch: 'Touch buttons\nto drive',
            },
            {
                key: 'SPACE for horn,\ndirects you\nto target',
                touch: 'Horn\ndirects you\nto target',
            },
            {
                key: "R to reset if you're stuck",
                touch: "Flip button\nappears when\nyou're stuck",
            },
            {
                key: 'Collect the deliveries & ...',
                touch: null,
            },
            {
                key: '... take them to the target',
                touch: null,
            },
        ];
        messages.forEach((message, i) => {
            setTimeout(() => {
                const touchEnabled = this.touchGrid.isVisible;
                let text = message.key;
                if (touchEnabled && message.touch !== null) {
                    text = message.touch;
                }
                this.setMessage(text, 5000, 1);
            }, i * 5000);
        });
    }
    setButtonState(action, isDown) {
        const button = this[action];
        const background = isDown ? TOUCH_DOWN_COLOR : TOUCH_UP_COLOR;
        const scaleX = isDown ? 0.9 : 1;
        const scaleY = scaleX;
        button.background = background;
        button.scaleX = scaleX;
        button.scaleY = scaleY;
    }
    setMessage(message, timeout = 5000, priority = 0) {
        if (this.lastMessagePriority !== null
            && this.lastMessagePriority > priority) {
            return;
        }
        if (this.textBlock.text !== message) {
            this.audioEngine.playAlert();
        }
        this.textRec.isVisible = true;
        this.imageBlock.isVisible = true;
        this.textBlock.text = message;
        if (this.timerHandler !== null) {
            clearTimeout(this.timerHandler);
        }
        this.lastMessagePriority = priority;
        this.timerHandler = setTimeout(() => {
            this.textRec.isVisible = false;
            this.imageBlock.isVisible = false;
            this.timerHandler = null;
            this.lastMessagePriority = null;
        }, timeout);
    }
    setVisisble(isVisible) {
        this.grid.isVisible = isVisible;
    }
    setShowAlert(show) {
        this.alertButton.isVisible = show;
    }
    setTargetName(name) {
        this.targetName = name;
    }
    setCarActionForTouch(pageX, pageY, isDown, identifier) {
        const x = pageX / window.innerWidth;
        const y = pageY / window.innerHeight;
        let currentTouchControl = null;
        this.touchControls.forEach((tc) => {
            if (tc.identifier === identifier) {
                currentTouchControl = tc;
            }
        });
        for (let i = 0; i < this.touchControls.length; i += 1) {
            const touchControl = this.touchControls[i];
            if ((x >= touchControl.minX)
                && (x < touchControl.maxX)
                && (y >= touchControl.minY)
                && (y < touchControl.maxY)) {
                if (touchControl !== currentTouchControl
                    && currentTouchControl !== null) {
                    currentTouchControl.identifier = null;
                    this.car.actions[currentTouchControl.action] = false;
                    this.setButtonState(currentTouchControl.action, false);
                }
                touchControl.identifier = identifier;
                this.car.actions[touchControl.action] = isDown;
                this.setButtonState(touchControl.action, isDown);
            }
        }
    }
    setTouchControlsEnabled(touchEnabled) {
        this.touchGrid.isVisible = touchEnabled;
        this.commsGrid.isVisible = !touchEnabled;
        this.alertBlock.text = touchEnabled ? STUCK_TOUCH : STUCK_KEYS;
        if (touchEnabled) {
            window.addEventListener('touchstart', this.onTouchStart);
            window.addEventListener('touchmove', this.onTouchMove);
            window.addEventListener('touchend', this.onTouchEnd);
        }
        else {
            window.removeEventListener('touchstart', this.onTouchStart);
            window.removeEventListener('touchmove', this.onTouchMove);
            window.removeEventListener('touchend', this.onTouchEnd);
        }
    }
    update() {
        if (this.endTime !== null) {
            return;
        }
        const now = Date.now();
        this.timerBlock.text = this.getCurrentTimeString(now);
    }
    getCurrentTimeString(now) {
        const duration = moment.duration(now - this.startTime);
        return `${duration.minutes().toString().padStart(2, '0')}:`
            + `${duration.seconds().toString().padStart(2, '0')}.`
            + `${duration.milliseconds().toString().padStart(3, '0')}s`;
    }
    setObjective(objective) {
        if (objective === this.objective) {
            return;
        }
        this.objective = objective;
        if (objective === null) {
            this.packageBlock.isVisible = false;
            return;
        }
        this.packageBlock.isVisible = true;
        switch (objective) {
            case objectives_1.Objective.PickUp:
                this.packageBlock.text = 'Pick up\nThe DELIVERY';
                break;
            case objectives_1.Objective.Goal:
                if (this.targetName !== null) {
                    this.packageBlock.text = `Deliver at\n${this.targetName}`;
                }
                break;
            default:
                break;
        }
    }
    resetTimer() {
        this.restartButton.isVisible = false;
        this.startTime = Date.now();
    }
    setGameOver() {
        this.restartButton.isVisible = true;
        const now = Date.now();
        const time = now - this.startTime;
        this.endTime = Date.now();
        const timeFormatted = this.getCurrentTimeString(this.endTime);
        let finalGrade = 'U';
        for (let i = 0; i < GRADES.length; i += 1) {
            const grade = GRADES[i];
            if (time < grade.time) {
                finalGrade = grade.grade;
                break;
            }
        }
        this.timerBlock.text = 'Final time:\n'
            + `${timeFormatted}\nGrade: ${finalGrade}`;
    }
    reset() {
        this.endTime = null;
        this.setMessage('Here we go again');
    }
}
exports.Comms = Comms;
