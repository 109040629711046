"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarLeftOrRight = exports.Direction = void 0;
const math_vector_1 = require("@babylonjs/core/Maths/math.vector");
var Direction;
(function (Direction) {
    Direction[Direction["Left"] = 0] = "Left";
    Direction[Direction["Right"] = 1] = "Right";
    Direction[Direction["Ahead"] = 2] = "Ahead";
    Direction[Direction["Behind"] = 3] = "Behind";
})(Direction = exports.Direction || (exports.Direction = {}));
class CarLeftOrRight {
    constructor(car, target) {
        this.car = car;
        this.target = target;
        this.direction = Direction.Ahead;
    }
    getDirection() {
        return this.direction;
    }
    setTarget(target) {
        this.target = target;
    }
    getDir(v) {
        const v1 = this.target.position.subtract(this.car.transformNode.position);
        const perp = math_vector_1.Vector3.Cross(v, v1);
        const dir = math_vector_1.Vector3.Dot(perp, this.car.transformNode.up);
        return dir;
    }
    getLeftRightDir() {
        return this.getDir(this.car.transformNode.forward);
    }
    getForwardBackDir() {
        const { forward } = this.car.transformNode;
        const left = new math_vector_1.Vector3(forward.z, forward.y, -forward.x);
        return this.getDir(left);
    }
    update() {
        const dir = this.getLeftRightDir();
        const fb = this.getForwardBackDir();
        const straightAheadDeadzoneHalf = 2;
        const isAhead = fb < 0;
        const angleInDeadsone = Math.abs(dir) < straightAheadDeadzoneHalf;
        if (isAhead && angleInDeadsone) {
            this.direction = Direction.Ahead;
        }
        else if (dir <= 0) {
            this.direction = Direction.Left;
        }
        else if (dir > 0) {
            this.direction = Direction.Right;
        }
    }
}
exports.CarLeftOrRight = CarLeftOrRight;
