"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.randomFromArray = void 0;
const math_scalar_1 = require("@babylonjs/core/Maths/math.scalar");
const randomFromArray = (array) => {
    const max = array.length;
    if (max === 0) {
        throw new Error('Array is empty');
    }
    return array[Math.round(math_scalar_1.Scalar.RandomRange(0, max - 1))];
};
exports.randomFromArray = randomFromArray;
