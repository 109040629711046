"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Game = void 0;
const AmmoPromise = require("ammojs-typed");
const Recast = require("recast-detour");
require("@babylonjs/core/Debug/debugLayer");
const advancedDynamicTexture_1 = require("@babylonjs/gui/2D/advancedDynamicTexture");
const ammoJSPlugin_1 = require("@babylonjs/core/Physics/Plugins/ammoJSPlugin");
const math_color_1 = require("@babylonjs/core/Maths/math.color");
const cubeTexture_1 = require("@babylonjs/core/Materials/Textures/cubeTexture");
const directionalLight_1 = require("@babylonjs/core/Lights/directionalLight");
const followCamera_1 = require("@babylonjs/core/Cameras/followCamera");
const freeCamera_1 = require("@babylonjs/core/Cameras/freeCamera");
const grid_1 = require("@babylonjs/gui/2D/controls/grid");
const hemisphericLight_1 = require("@babylonjs/core/Lights/hemisphericLight");
const mesh_1 = require("@babylonjs/core/Meshes/mesh");
const meshBuilder_1 = require("@babylonjs/core/Meshes/meshBuilder");
const physicsImpostor_1 = require("@babylonjs/core/Physics/physicsImpostor");
const shadowGenerator_1 = require("@babylonjs/core/Lights/Shadows/shadowGenerator");
const standardMaterial_1 = require("@babylonjs/core/Materials/standardMaterial");
const texture_1 = require("@babylonjs/core/Materials/Textures/texture");
const math_1 = require("@babylonjs/core/Maths/math");
const recastJSPlugin_1 = require("@babylonjs/core/Navigation/Plugins/recastJSPlugin");
require("./style.sass");
const messages = require("./messages");
const settings = require("./settings");
const day_nx_png_1 = require("./assets/textures/skybox/day_nx.png");
const day_ny_png_1 = require("./assets/textures/skybox/day_ny.png");
const day_nz_png_1 = require("./assets/textures/skybox/day_nz.png");
const day_px_png_1 = require("./assets/textures/skybox/day_px.png");
const day_py_png_1 = require("./assets/textures/skybox/day_py.png");
const day_pz_png_1 = require("./assets/textures/skybox/day_pz.png");
const aiCar_1 = require("./aiCar");
const CarAi_1 = require("./CarAi");
const CarLeftOrRight_1 = require("./CarLeftOrRight");
const Comms_1 = require("./Comms");
const Goal_1 = require("./Goal");
const GoalsManager_1 = require("./GoalsManager");
const Load_1 = require("./Load");
const playerCar_1 = require("./playerCar");
const objectives_1 = require("./objectives");
const util_1 = require("./util");
const randomFromArray_1 = require("./randomFromArray");
const screamFloorY = -20;
const killFloorY = -50;
const buildFromNavmeshData = (navigationPlugin, data) => {
    const nDataBytes = data.length * data.BYTES_PER_ELEMENT;
    // eslint-disable-next-line no-underscore-dangle
    const dataPtr = navigationPlugin.bjsRECAST._malloc(nDataBytes);
    const dataHeap = new Uint8Array(navigationPlugin.bjsRECAST.HEAPU8.buffer, dataPtr, nDataBytes);
    dataHeap.set(data);
    const buf = new navigationPlugin.bjsRECAST.NavmeshData();
    buf.dataPointer = dataHeap.byteOffset;
    buf.size = data.length;
    navigationPlugin.navMesh = new navigationPlugin.bjsRECAST.NavMesh();
    navigationPlugin.navMesh.buildFromNavmeshData(buf);
    // Free memory
    // eslint-disable-next-line no-underscore-dangle
    navigationPlugin.bjsRECAST._free(dataHeap.byteOffset);
};
class Game {
    constructor(touchEnabled, scaleFactor, numberOfAiCars, navmesh, audioEngine, scene) {
        this.touchEnabled = touchEnabled;
        this.scaleFactor = scaleFactor;
        this.numberOfAiCars = numberOfAiCars;
        this.navmesh = navmesh;
        this.audioEngine = audioEngine;
        this.scene = scene;
        this.scene.autoClear = false; // Color buffer
        this.scene.autoClearDepthAndStencil = false; // Depth and stencil, obviously
        const physicsPlugin = new ammoJSPlugin_1.AmmoJSPlugin(true, AmmoPromise);
        scene.enablePhysics(new math_1.Vector3(0, -9.81, 0), physicsPlugin);
        const startPosition = new math_1.Vector3(0, 2, 0);
        const startRotation = new math_1.Quaternion();
        const baseCarOptions = {
            cabColor: new math_color_1.Color3(0, 0.4, 1),
            chassisHeight: 0.6,
            chassisLength: 4,
            chassisWidth: 2.5,
            massVehicle: 200,
            maxBreakingForce: 30,
            maxEngineForce: 500,
        };
        const playerCarOptions = Object.assign(Object.assign({}, baseCarOptions), { massVehicle: baseCarOptions.massVehicle * 10, maxBreakingForce: baseCarOptions.maxBreakingForce * 6.667, maxEngineForce: baseCarOptions.maxEngineForce * 40 });
        this.car = new playerCar_1.PlayerCar(startPosition, startRotation, playerCarOptions, this.audioEngine, scene, physicsPlugin.bjsAMMO);
        const ui = advancedDynamicTexture_1.AdvancedDynamicTexture.CreateFullscreenUI('ui', true, scene, texture_1.Texture.NEAREST_SAMPLINGMODE);
        const setImageSmoothing = () => {
            ui.scaleTo(window.innerWidth / this.scaleFactor, window.innerHeight / this.scaleFactor);
            ui.getContext().imageSmoothingEnabled = false;
        };
        window.addEventListener('resize', () => {
            setImageSmoothing();
        });
        setImageSmoothing();
        const grid = new grid_1.Grid();
        grid.addColumnDefinition(1);
        grid.addRowDefinition(1 / 3);
        grid.addRowDefinition(1 / 3);
        grid.addRowDefinition(1 / 3);
        ui.addControl(grid);
        const touchGrid = new grid_1.Grid();
        ui.addControl(touchGrid);
        const comms = new Comms_1.Comms(scaleFactor, this.car, this.audioEngine, grid, touchGrid);
        comms.setVisisble(settings.showUi());
        comms.setTouchControlsEnabled(this.touchEnabled);
        scene.registerBeforeRender(() => {
            comms.update();
        });
        const skybox = meshBuilder_1.MeshBuilder.CreateBox('skyBox', { size: 1000.0 }, scene);
        const skyboxMaterial = new standardMaterial_1.StandardMaterial('skyBox', scene);
        skyboxMaterial.backFaceCulling = false;
        skyboxMaterial.reflectionTexture = new cubeTexture_1.CubeTexture('./assets/textures/skybox/day', scene, null, false, [day_px_png_1.default, day_py_png_1.default, day_pz_png_1.default, day_nx_png_1.default, day_ny_png_1.default, day_nz_png_1.default]);
        skyboxMaterial.reflectionTexture.coordinatesMode = texture_1.Texture.SKYBOX_MODE;
        skyboxMaterial.diffuseColor = new math_color_1.Color3(0, 0, 0);
        skyboxMaterial.specularColor = new math_color_1.Color3(0, 0, 0);
        skybox.material = skyboxMaterial;
        // eslint-disable-next-line no-new
        new hemisphericLight_1.HemisphericLight('light', new math_1.Vector3(1, 1, 0), scene);
        const light = new directionalLight_1.DirectionalLight('shadowLight', new math_1.Vector3(0, -1, 0), scene);
        light.position.set(0, 30, 0);
        const shadowGenerator = new shadowGenerator_1.ShadowGenerator(1024, light);
        const goalManager = new GoalsManager_1.GoalsManager(comms, this.car, this.audioEngine);
        scene.audioListenerPositionProvider = () => this.car.transformNode.position;
        shadowGenerator.getShadowMap().renderList.push(this.car.mesh);
        scene.registerBeforeRender(() => {
            this.car.update();
            const now = Date.now();
            comms.setShowAlert(this.car.isUpsideDown && this.car.lastUprightChange < now - 1500);
            comms.setTouchControlsEnabled(this.touchEnabled);
        });
        const loadStartPosition = startPosition.add(new math_1.Vector3(0, 4, 40));
        const loadMesh = meshBuilder_1.MeshBuilder.CreateBox('load', { size: 1 });
        loadMesh.position.copyFrom(loadStartPosition);
        loadMesh.physicsImpostor = new physicsImpostor_1.PhysicsImpostor(loadMesh, physicsImpostor_1.PhysicsImpostor.BoxImpostor, {
            mass: 2,
            restitution: 1,
            friction: 1,
        }, scene);
        const body = loadMesh.physicsImpostor.physicsBody;
        body.setDamping(0.25, 0.25);
        shadowGenerator.getShadowMap().renderList.push(loadMesh);
        const load = new Load_1.Load(loadMesh, this.car, comms, this.audioEngine);
        const carLeftOrRight = new CarLeftOrRight_1.CarLeftOrRight(this.car, loadMesh);
        let lastHorn = false;
        this.car.speechBubbles.mesh.isVisible = false;
        scene.registerBeforeRender(() => {
            const direction = carLeftOrRight.getDirection();
            if (direction === CarLeftOrRight_1.Direction.Left) {
                this.car.speechBubbles.setText('<  ', null, false);
            }
            else if (direction === CarLeftOrRight_1.Direction.Right) {
                this.car.speechBubbles.setText('  >', null, false);
            }
            else {
                this.car.speechBubbles.setText(' ^ ', null, false);
            }
            this.car.speechBubbles.mesh.isVisible = this.car.actions.horn;
            if (this.car.actions.horn) {
                if (!lastHorn) {
                    this.audioEngine.playHorn();
                }
            }
            else if (lastHorn) {
                this.audioEngine.stopHorn();
            }
            lastHorn = this.car.actions.horn;
        });
        const otherCars = [];
        const carAis = [];
        scene.registerBeforeRender(() => {
            carAis.forEach((c) => {
                c.update();
            });
        });
        // Side effect of stopping messages at the beginning
        let lastMessageAt = Date.now();
        const carOptions = (0, util_1.cycle)([
            Object.assign(Object.assign({}, baseCarOptions), { cabColor: new math_color_1.Color3(1, 0, 0.4) }),
            Object.assign(Object.assign({}, baseCarOptions), { chassisWidth: 3, chassisHeight: 0.8, chassisLength: 3, cabColor: new math_color_1.Color3(1, 0.4, 0) }),
            {
                chassisWidth: 4,
                chassisHeight: 2,
                chassisLength: 8,
                massVehicle: 200,
                cabColor: new math_color_1.Color3(1, 0.8, 0),
            },
            Object.assign(Object.assign({}, baseCarOptions), { cabColor: new math_color_1.Color3(1, 0, 1) }),
            Object.assign(Object.assign({}, baseCarOptions), { cabColor: new math_color_1.Color3(0.6, 0.6, 0.6) }),
            {
                chassisWidth: 4,
                chassisHeight: 0.6,
                chassisLength: 8,
                massVehicle: 200,
                cabColor: new math_color_1.Color3(0.2, 0.8, 0.8),
            },
        ]);
        const halfNumberOfAiCars = Math.floor(this.numberOfAiCars / 2);
        for (let i = -halfNumberOfAiCars; i < halfNumberOfAiCars; i += 1) {
            const newStart = startPosition.add(new math_1.Vector3(6 + i * 7, 2, -100));
            const options = carOptions();
            const newCar = new aiCar_1.AiCar(newStart, startRotation, options, audioEngine, scene, physicsPlugin.bjsAMMO);
            shadowGenerator.getShadowMap().renderList.push(newCar.mesh);
            let lastCrashAt = Date.now();
            this.car.addCollidable(newCar.body, () => {
                const now = Date.now();
                if (now - 1500 > lastCrashAt) {
                    this.audioEngine.playCrash();
                    lastCrashAt = now;
                }
                if (now - 5000 < lastMessageAt) {
                    return;
                }
                lastMessageAt = now;
                let message;
                if (newCar.isUpsideDown && newCar.lastUprightChange < now - 3000) {
                    message = (0, randomFromArray_1.randomFromArray)(messages.PLAYER_CRASH_DOWN_MESSAGES);
                }
                else {
                    message = (0, randomFromArray_1.randomFromArray)(messages.PLAYER_CRASH_MESSAGES);
                }
                comms.setMessage(message, 3000);
            });
            otherCars.push(newCar);
            const ai = new CarAi_1.CarAi(newCar, newStart, startRotation, new CarLeftOrRight_1.CarLeftOrRight(newCar, loadMesh), load, this.audioEngine, screamFloorY, killFloorY, 10 * 1000, 0.1, goalManager);
            ai.setIsEnabled(settings.enableAi());
            carAis.push(ai);
            scene.registerBeforeRender(() => {
                newCar.update();
            });
        }
        const camera = new followCamera_1.FollowCamera('FollowCam', new math_1.Vector3(0, 0, 10), scene);
        camera.radius = 20;
        camera.heightOffset = 4;
        camera.rotationOffset = 180;
        camera.cameraAcceleration = 0.05;
        camera.maxCameraSpeed = 400;
        camera.lockedTarget = this.car.transformNode;
        scene.activeCamera = camera;
        const freeCamera = new freeCamera_1.FreeCamera('FreeCam', new math_1.Vector3(0, 2, 10), scene);
        const canvas = scene.getEngine().getRenderingCanvas();
        freeCamera.attachControl(canvas, true);
        freeCamera.speed = 2;
        scene.registerBeforeRender(() => {
            carLeftOrRight.update();
        });
        this.car.addCollidable(loadMesh.physicsImpostor.physicsBody, () => {
            load.bOnCollision(this.car);
        });
        otherCars.forEach((c) => {
            c.addCollidable(loadMesh.physicsImpostor.physicsBody, () => {
                load.bOnCollision(c);
            });
        });
        scene.registerBeforeRender(() => {
            if (loadMesh.position.y < killFloorY) {
                comms.setMessage('Package fell\nNew one in the middle');
                this.audioEngine.playLost();
                load.reset(loadStartPosition);
            }
            load.update();
            const goal = goalManager.getCurrentGoal();
            let target = load.getMesh();
            if (goal !== null && goal.getIsCurrent()) {
                const loaded = load.isLoaded();
                goal.setHighlight(loaded);
                comms.setTargetName(goal.getName());
                if (loaded) {
                    target = goal.getMesh();
                    comms.setObjective(objectives_1.Objective.Goal);
                }
                else {
                    comms.setObjective(objectives_1.Objective.PickUp);
                }
            }
            else {
                comms.setTargetName(null);
                comms.setObjective(null);
            }
            carLeftOrRight.setTarget(target);
        });
        let wahSound = null;
        const reset = () => {
            this.car.reset(startPosition, startRotation);
            if (wahSound !== null) {
                if (wahSound.isPlaying) {
                    wahSound.stop();
                }
                wahSound.detachFromMesh();
                wahSound = null;
            }
            camera.position.set(0, 30, -30);
            comms.reset();
        };
        comms.restartButton.onPointerUpObservable.add(() => {
            reset();
            comms.resetTimer();
            goalManager.reset();
            this.audioEngine.reset();
            load.reset(loadStartPosition);
        });
        scene.registerBeforeRender(() => {
            if (wahSound === null
                && this.car.transformNode.position.y < screamFloorY) {
                wahSound = audioEngine.playWah(this.car.transformNode);
            }
            if (this.car.transformNode.position.y < killFloorY) {
                reset();
            }
        });
        const navigationMeshes = [];
        scene.meshes.forEach((m) => {
            // eslint-disable-next-line no-param-reassign
            const parts = m.name.split('.');
            const baseName = parts[0];
            const name = parts.length > 1 ? parts[1] : '';
            switch (baseName) {
                case 'Bolt':
                    m.setParent(null);
                    // eslint-disable-next-line no-param-reassign
                    m.physicsImpostor = new physicsImpostor_1.PhysicsImpostor(m, physicsImpostor_1.PhysicsImpostor.SphereImpostor, { mass: 1, restitution: 0.9 }, scene);
                    shadowGenerator.getShadowMap().renderList.push(m);
                    break;
                case 'Ground': {
                    m.receiveShadows = true;
                    m.setParent(null);
                    m.physicsImpostor = new physicsImpostor_1.PhysicsImpostor(m, physicsImpostor_1.PhysicsImpostor.MeshImpostor, { mass: 0, restitution: 0.9 }, scene);
                    load.addGroundCollision(m.physicsImpostor);
                    if (m instanceof mesh_1.Mesh) {
                        navigationMeshes.push(m);
                    }
                    if (name === '') {
                        m.alwaysSelectAsActiveMesh = true;
                    }
                    m.freezeWorldMatrix();
                    break;
                }
                case 'Goal': {
                    const newGoal = m.clone('clone', m.parent);
                    newGoal.parent = null;
                    newGoal.material = m.material.clone('clone');
                    const invertParentWorldMatrix = m.parent.getWorldMatrix().clone();
                    invertParentWorldMatrix.invert();
                    newGoal.position.copyFrom(math_1.Vector3.TransformCoordinates(m.position, invertParentWorldMatrix));
                    newGoal.scaling.copyFrom(math_1.Vector3.TransformCoordinates(m.scaling, invertParentWorldMatrix));
                    m.dispose();
                    newGoal.physicsImpostor = new physicsImpostor_1.PhysicsImpostor(newGoal, physicsImpostor_1.PhysicsImpostor.MeshImpostor, { mass: 0, restitution: 0.9, ignoreParent: true }, newGoal.getScene());
                    const goal = new Goal_1.Goal(name, newGoal, load);
                    this.addGoal(newGoal.physicsImpostor.physicsBody, load, goal, goalManager);
                    shadowGenerator.getShadowMap().renderList.push(newGoal);
                    if (newGoal instanceof mesh_1.Mesh) {
                        navigationMeshes.push(newGoal);
                    }
                    break;
                }
                default:
                    break;
            }
        });
        const navigationPlugin = new recastJSPlugin_1.RecastJSPlugin(Recast);
        buildFromNavmeshData(navigationPlugin, this.navmesh);
        let navmeshdebug = null;
        const matdebug = new standardMaterial_1.StandardMaterial('matdebug', scene);
        matdebug.diffuseColor = new math_color_1.Color3(0.1, 0.2, 1);
        matdebug.alpha = 0.2;
        matdebug.freeze();
        const createDebugNavMesh = () => {
            let isVisible = false;
            if (navmeshdebug !== null) {
                isVisible = navmeshdebug.isVisible;
                navmeshdebug.dispose();
                navmeshdebug = null;
            }
            navmeshdebug = navigationPlugin.createDebugNavMesh(scene);
            navmeshdebug.material = matdebug;
            navmeshdebug.isVisible = isVisible;
            navmeshdebug.freezeWorldMatrix();
        };
        createDebugNavMesh();
        carAis.forEach((ai) => {
            ai.setNavigationSystem(navigationPlugin);
        });
        goalManager.nextGoal();
        const updateCarControls = (key, isDown) => {
            switch (key.toLowerCase()) {
                case '1':
                    if (isDown) {
                        if (scene.activeCamera !== camera) {
                            camera.position.copyFrom(freeCamera.position);
                        }
                        scene.activeCamera = camera;
                    }
                    break;
                case '2':
                    if (isDown) {
                        scene.activeCamera = freeCamera;
                    }
                    break;
                case '3':
                    if (isDown) {
                        const isVisible = !navmeshdebug.isVisible;
                        navmeshdebug.isVisible = isVisible;
                        carAis.forEach((ai) => {
                            ai.showNavDestination(isVisible);
                        });
                    }
                    break;
                case '4': {
                    if (!isDown) {
                        break;
                    }
                    // XXX: Copied from the github head
                    const navmeshData = navigationPlugin.navMesh.getNavmeshData();
                    const arrView = new Uint8Array(navigationPlugin.bjsRECAST.HEAPU8.buffer, navmeshData.dataPointer, navmeshData.size);
                    const bytes = new Uint8Array(navmeshData.size);
                    bytes.set(arrView);
                    navigationPlugin.navMesh.freeNavmeshData(navmeshData);
                    const blob = new Blob([bytes]);
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'world.navmesh';
                    link.click();
                    break;
                }
                case '5':
                    if (!isDown) {
                        break;
                    }
                    navigationPlugin.createNavMesh(navigationMeshes, {
                        cs: 1,
                        ch: 1,
                        walkableSlopeAngle: 35,
                        walkableHeight: 1,
                        walkableClimb: 1,
                        walkableRadius: 0.5,
                        maxEdgeLen: 12,
                        maxSimplificationError: 1.3,
                        minRegionArea: 8,
                        mergeRegionArea: 20,
                        maxVertsPerPoly: 6,
                        detailSampleDist: 6,
                        detailSampleMaxError: 1,
                    });
                    createDebugNavMesh();
                    break;
                case '6':
                    if (!isDown) {
                        break;
                    }
                    if (this.scene.debugLayer.isVisible()) {
                        this.scene.debugLayer.hide();
                    }
                    else {
                        this.scene.debugLayer.show({
                            overlay: true,
                        });
                    }
                    break;
                case 'w':
                    this.handleAccelerate(isDown);
                    break;
                case 'a':
                    this.car.actions.left = isDown;
                    break;
                case 's':
                    this.car.actions.brake = isDown;
                    break;
                case 'd':
                    this.car.actions.right = isDown;
                    break;
                case 'r':
                    this.car.resetFlipCurrent();
                    break;
                case ' ':
                    this.car.actions.horn = isDown;
                    break;
                default:
                    break;
            }
        };
        window.addEventListener('keydown', (e) => {
            this.touchEnabled = false;
            updateCarControls(e.key, true);
        });
        window.addEventListener('keyup', (e) => {
            updateCarControls(e.key, false);
        });
        window.addEventListener('touchstart', () => {
            this.touchEnabled = true;
        });
    }
    addGoal(physicsBody, load, goal, goalManager) {
        goalManager.addGoal(goal);
        this.car.addCollidable(physicsBody, () => {
            if (load.isLoaded() && goal === goalManager.getCurrentGoal()) {
                load.reset(new math_1.Vector3(0, 4, 10));
            }
            goalManager.updateGoal(goal);
        });
    }
    handleAccelerate(isActive) {
        this.car.actions.accelerate = isActive;
    }
    getEngine() {
        return this.scene.getEngine();
    }
    run() {
        this.getEngine().runRenderLoop(() => {
            this.scene.render();
        });
    }
}
exports.Game = Game;
