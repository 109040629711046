"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ifUndefined = exports.randomBetween = exports.clamp = exports.cycle = exports.randomInt = void 0;
const randomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
};
exports.randomInt = randomInt;
const cycle = (values, start) => {
    let i;
    if (start === undefined) {
        i = 0;
    }
    else if (start === 'random') {
        i = (0, exports.randomInt)(0, values.length);
    }
    else {
        i = start;
    }
    return () => {
        const value = values[i];
        i = (i + 1) % values.length;
        return value;
    };
};
exports.cycle = cycle;
const clamp = (value, min, max) => {
    if (value <= min) {
        return min;
    }
    if (value >= max) {
        return max;
    }
    return value;
};
exports.clamp = clamp;
const randomBetween = (minInclusive, maxExclusive) => (minInclusive + Math.random() * (maxExclusive - minInclusive));
exports.randomBetween = randomBetween;
const ifUndefined = (value, defaultValue) => {
    if (value === undefined) {
        return defaultValue;
    }
    return value;
};
exports.ifUndefined = ifUndefined;
