"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Load = void 0;
const math_color_1 = require("@babylonjs/core/Maths/math.color");
const math_1 = require("@babylonjs/core/Maths/math");
const Car_1 = require("./Car");
const SpeechBubbles_1 = require("./SpeechBubbles");
const HighlightCone_1 = require("./HighlightCone");
class Load {
    constructor(mesh, car, comms, audioEngine) {
        this.mesh = mesh;
        this.car = car;
        this.comms = comms;
        this.audioEngine = audioEngine;
        this.isColliding = false;
        this.isCollidingWithPlayer = false;
        this.normalColor = new math_color_1.Color3(0, 1, 0.3);
        this.notLoadedColor = new math_color_1.Color3(1, 0, 0.3);
        this.timeSinceLastCollision = 0;
        this.timeSinceLastPlayerCollision = null;
        this.isLoadedTimeout = 300;
        this.screamTimeout = this.isLoadedTimeout / 5;
        this.touchingGround = false;
        this.canPlayDrop = false;
        this.ownerCar = null;
        this.bOnCollision = (car) => {
            this.onCollision(car);
        };
        // this.mesh.material = new StandardMaterial('load', mesh.getScene());
        this.speechBubble = new SpeechBubbles_1.SpeechBubbles(this.mesh);
        this.speechBubble.mesh.position.y -= 10.3;
        this.highlightCone = new HighlightCone_1.HighlightCone(this.speechBubble.mesh);
        this.speechBubble.mesh.scaling.set(1, -1, 1);
    }
    addGroundCollision(impostor) {
        this.mesh.physicsImpostor.registerOnPhysicsCollide(impostor, () => {
            if (this.canPlayDrop) {
                this.audioEngine.playDrop();
                this.canPlayDrop = false;
            }
            this.touchingGround = true;
        });
    }
    onCollision(car) {
        // pick up if its another car, and only if its us and not loaded
        if ((this.car !== car && !this.isPickedUp())
            || (this.car === car && !this.isLoaded())) {
            this.reset(car);
        }
        if (car === this.car) {
            this.isCollidingWithPlayer = true;
            this.timeSinceLastPlayerCollision = 0;
        }
        this.isColliding = true;
        this.timeSinceLastCollision = 0;
    }
    setColor(color) {
        // const { material } = this.mesh;
        // if (!(material instanceof StandardMaterial)) {
        //   throw new Error('Not StandardMaterial');
        // }
        // material.emissiveColor = color;
    }
    isLoaded() {
        return this.isCollidingWithPlayer
            || (this.timeSinceLastPlayerCollision !== null
                && this.timeSinceLastPlayerCollision < this.isLoadedTimeout);
    }
    isPickedUp() {
        return this.isColliding
            || this.timeSinceLastCollision < this.isLoadedTimeout;
    }
    getMesh() {
        return this.mesh;
    }
    reset(obj) {
        let position;
        if (obj instanceof Car_1.Car) {
            const car = obj;
            const y = car.mesh.getBoundingInfo().maximum.y
                + this.mesh.getBoundingInfo().maximum.y / 2;
            position = math_1.Vector3.TransformCoordinates(new math_1.Vector3(0, y, 0), car.mesh.getWorldMatrix());
            const carLV = car.body.getLinearVelocity();
            this.mesh.physicsImpostor.setLinearVelocity(new math_1.Vector3(carLV.x(), carLV.y(), carLV.z()));
            if (this.car === car) {
                this.canPlayDrop = true;
                this.speechBubble.setText('Deliver me!', 3000);
                this.comms.setMessage('Got it!');
            }
            else {
                this.comms.setMessage('Some one stole\nthe delivery!');
            }
            this.audioEngine.playPop(this.mesh);
            this.ownerCar = car;
        }
        else {
            position = obj;
            this.canPlayDrop = false;
            this.touchingGround = true;
            this.setIsNotLoaded();
            this.mesh.physicsImpostor.setLinearVelocity(new math_1.Vector3());
        }
        this.mesh.physicsImpostor.setAngularVelocity(new math_1.Vector3());
        this.mesh.position.copyFrom(position);
        this.mesh.rotationQuaternion.copyFrom(this.car.transformNode.rotationQuaternion);
        this.timeSinceLastCollision = 0;
    }
    isOwnerCar(car) {
        return car === this.ownerCar;
    }
    setIsLoaded() {
        // this.speechBubble.mesh.scaling.set(0.2, 0.2, 0.2);
        this.setColor(this.normalColor);
        this.highlightCone.mesh.isVisible = false;
    }
    setIsNotLoaded() {
        // this.speechBubble.mesh.scaling.set(1, 1, 1);
        this.highlightCone.mesh.isVisible = true;
        this.speechBubble.mesh.isVisible = true;
        this.speechBubble.setText('Pick up!');
        this.setColor(this.notLoadedColor);
    }
    update() {
        this.mesh.physicsImpostor.wakeUp();
        if (this.isColliding) {
            this.touchingGround = false;
        }
        const dt = this.mesh.getEngine().getDeltaTime();
        if (!this.isCollidingWithPlayer && this.touchingGround) {
            this.timeSinceLastPlayerCollision += dt;
        }
        if (!this.isColliding) {
            this.timeSinceLastCollision += dt;
            if (this.timeSinceLastCollision >= this.screamTimeout) {
                this.speechBubble.setText('Ahh!', 1500);
            }
            if (this.touchingGround) {
                if (this.timeSinceLastCollision >= this.isLoadedTimeout) {
                    this.setIsNotLoaded();
                }
            }
            return;
        }
        if (this.isCollidingWithPlayer) {
            this.setIsLoaded();
        }
        this.isCollidingWithPlayer = false;
        this.isColliding = false;
    }
}
exports.Load = Load;
