"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AiCar = void 0;
const Car_1 = require("./Car");
class AiCar extends Car_1.Car {
    initAudio() {
        this.audioEngine.attatchEngineToMesh(this.transformNode);
    }
    disposeAudio() {
        this.audioEngine.detatchEngineFromMesh(this.transformNode);
    }
    updateAudio() {
        this.audioEngine.setEngineSpeed(this.transformNode, this.vehicle.getCurrentSpeedKmHour());
    }
}
exports.AiCar = AiCar;
