"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.load = exports.AudioEngine = void 0;
const sound_1 = require("@babylonjs/core/Audio/sound");
const settings = require("./settings");
const alert_mp3_1 = require("./assets/audio/alert.mp3");
const brakes_mp3_1 = require("./assets/audio/brakes.mp3");
const crash_mp3_1 = require("./assets/audio/crash.mp3");
const delivered_1_mp3_1 = require("./assets/audio/delivered-1.mp3");
const delivered_2_mp3_1 = require("./assets/audio/delivered-2.mp3");
const delivered_3_mp3_1 = require("./assets/audio/delivered-3.mp3");
const drop_1_mp3_1 = require("./assets/audio/drop-1.mp3");
const drop_2_mp3_1 = require("./assets/audio/drop-2.mp3");
const drop_3_mp3_1 = require("./assets/audio/drop-3.mp3");
const engine_mp3_1 = require("./assets/audio/engine.mp3");
const fanfare_mp3_1 = require("./assets/audio/fanfare.mp3");
const gameover_mp3_1 = require("./assets/audio/gameover.mp3");
const horn_mp3_1 = require("./assets/audio/horn.mp3");
const lost_1_mp3_1 = require("./assets/audio/lost-1.mp3");
const lost_2_mp3_1 = require("./assets/audio/lost-2.mp3");
const music_mp3_1 = require("./assets/audio/music.mp3");
const new_1_mp3_1 = require("./assets/audio/new-1.mp3");
const pick_up_1_mp3_1 = require("./assets/audio/pick-up-1.mp3");
const pick_up_2_mp3_1 = require("./assets/audio/pick-up-2.mp3");
const pick_up_3_mp3_1 = require("./assets/audio/pick-up-3.mp3");
const pop_mp3_1 = require("./assets/audio/pop.mp3");
const right_place_no_package_mp3_1 = require("./assets/audio/right-place-no-package.mp3");
const wah_mp3_1 = require("./assets/audio/wah.mp3");
const wrong_place_mp3_1 = require("./assets/audio/wrong-place.mp3");
const util_1 = require("./util");
class Engine {
    constructor(sound, volume = 1, playbackRateOffset = 0) {
        this.sound = sound;
        this.volume = volume;
        this.playbackRateOffset = playbackRateOffset;
        this.isPlaying = false;
        this.sound.distanceModel = 'exponential';
        this.sound.rolloffFactor = 1.05;
        this.sound.play();
        this.sound.setVolume(0);
    }
    dispose() {
        this.sound.dispose();
    }
    attatchToMesh(mesh) {
        this.sound.attachToMesh(mesh);
    }
    detatchFromMesh() {
        this.sound.detachFromMesh();
    }
    setSpeed(speed) {
        const ratio = (0, util_1.clamp)(Math.abs(speed), 0, 100) / 100;
        const timeout = 0.25;
        if (ratio <= 0.05) {
            if (this.isPlaying) {
                this.sound.setVolume(0, timeout);
                this.isPlaying = false;
            }
            return;
        }
        this.sound.setPlaybackRate(0.5 + ratio + this.playbackRateOffset);
        if (!this.isPlaying) {
            this.sound.setVolume(this.volume, timeout);
            this.isPlaying = true;
        }
    }
}
class AudioEngine {
    constructor(init) {
        this.engines = new Map();
        this.currentVoiceOver = null;
        this.playerIsBraking = false;
        // -- SFX --
        this.musicDucking = false;
        this.playerBrakes = init.brakes;
        this.playerBrakes.setVolume(0.5);
        this.protoEngine = init.engine;
        this.protoEngine.loop = true;
        this.playerEngine = new Engine(this.protoEngine.clone(), 0.1);
        this.music = init.music;
        this.music.loop = true;
        this.music.setVolume(0.3);
        if (settings.playMusic()) {
            this.music.play(0, 4 * (60 / 120));
        }
        this.alert = init.alert;
        this.alert.setVolume(0.2);
        this.crash = init.crash;
        this.fanfare = init.fanfare;
        this.fanfare.setVolume(0.6);
        this.horn = init.horn;
        this.horn.setVolume(0.6);
        this.horn.setPlaybackRate(1.4);
        this.gameover = init.gameover;
        this.pop = init.pop;
        this.pop.maxDistance = 500;
        this.delivereds = (0, util_1.cycle)([
            init.delivered1,
            init.delivered2,
            init.delivered3,
        ], 'random');
        this.drops = (0, util_1.cycle)([
            init.drop1,
            init.drop2,
            init.drop3,
        ], 'random');
        this.losts = (0, util_1.cycle)([
            init.lost1,
            init.lost2,
        ], 'random');
        this.newDeliveries = (0, util_1.cycle)([
            init.newDelivery1,
            init.pickUp1,
            init.pickUp2,
            init.pickUp3,
        ], 'random');
        this.pickUps = (0, util_1.cycle)([
            init.pickUp1,
            init.pickUp2,
            init.pickUp3,
        ], 'random');
        this.rightPlaceNoPackage = init.rightPlaceNoPackage;
        this.wah = init.wah;
        this.wrongPlace = init.wrongPlace;
    }
    dispose() {
        for (const engine of this.engines.values()) {
            engine.dispose();
        }
        this.engines.clear();
        this.playerEngine.dispose();
        this.playerBrakes.dispose();
        this.protoEngine.dispose();
        this.music.dispose();
    }
    // -- Car --
    attatchEngineToMesh(mesh) {
        const engine = new Engine(this.protoEngine.clone(), 0.2, (0, util_1.randomBetween)(-0.25, 0.25));
        engine.attatchToMesh(mesh);
        this.engines.set(mesh, engine);
    }
    getEngine(mesh) {
        return this.engines.get(mesh);
    }
    detatchEngineFromMesh(mesh) {
        const engine = this.getEngine(mesh);
        this.engines.delete(mesh);
        engine.detatchFromMesh();
        engine.dispose();
    }
    setEngineSpeed(mesh, speed) {
        this.getEngine(mesh).setSpeed(speed);
    }
    setPlayerEngineSpeed(speed) {
        this.playerEngine.setSpeed(speed);
    }
    setPlayerBraking(isBraking) {
        const wasBraking = this.playerIsBraking;
        if (isBraking === wasBraking) {
            return;
        }
        this.playerIsBraking = isBraking;
        if (isBraking) {
            if (!this.playerBrakes.isPlaying) {
                this.playerBrakes.setPlaybackRate((0, util_1.randomBetween)(0.9, 1.1));
                this.playerBrakes.play();
            }
        }
        else if (this.playerBrakes.isPlaying) {
            this.playerBrakes.stop();
        }
    }
    playCrash() {
        this.crash.setPlaybackRate((0, util_1.randomBetween)(0.5, 1));
        if (!this.crash.isPlaying) {
            this.crash.play();
        }
    }
    playFanfare() {
        this.fanfare.setPlaybackRate((0, util_1.randomBetween)(0.8, 1.2));
        if (!this.fanfare.isPlaying) {
            this.fanfare.play();
        }
        if (!this.musicDucking) {
            this.musicDucking = true;
            this.music.setVolume(0, 0.1);
            this.fanfare.onended = () => {
                this.fanfare.onended = null;
                this.musicDucking = false;
                this.music.setVolume(0.4, 0.1);
            };
        }
    }
    playGameOver() {
        this.gameover.play();
        this.music.setPlaybackRate(0.66);
        if (!this.musicDucking) {
            this.musicDucking = true;
            this.music.setVolume(0, 0.1);
            this.gameover.onended = () => {
                this.gameover.onended = null;
                this.musicDucking = false;
                this.music.setVolume(0.4, 0.1);
            };
        }
    }
    playAlert() {
        if (!this.alert.isPlaying) {
            this.alert.play();
        }
    }
    playHorn() {
        if (!this.horn.isPlaying) {
            this.horn.play();
        }
    }
    stopHorn() {
        if (this.horn.isPlaying) {
            this.horn.stop();
        }
    }
    playPop(mesh) {
        this.pop.detachFromMesh();
        this.pop.attachToMesh(mesh);
        if (!this.pop.isPlaying) {
            this.pop.play();
        }
    }
    playWah(mesh) {
        const wah = this.wah.clone();
        wah.attachToMesh(mesh);
        wah.maxDistance = 200;
        wah.play();
        return wah;
    }
    reset() {
        this.music.setPlaybackRate(1);
    }
    // -- Voice Over --
    playVoiceOver(voiceOver) {
        if (this.currentVoiceOver !== null) {
            this.currentVoiceOver.stop();
        }
        this.currentVoiceOver = voiceOver;
        if (!voiceOver.isPlaying) {
            voiceOver.play();
        }
        if (!this.musicDucking) {
            this.musicDucking = true;
            this.music.setVolume(0.1, 0.1);
            voiceOver.onended = () => {
                voiceOver.onended = null;
                this.musicDucking = false;
                this.music.setVolume(0.4, 0.1);
            };
        }
    }
    playVoiceOverFrom(voiceOvers) {
        this.playVoiceOver(voiceOvers());
    }
    playDelivered() {
        this.playVoiceOverFrom(this.delivereds);
    }
    playDrop() {
        this.playVoiceOverFrom(this.drops);
    }
    playLost() {
        this.playVoiceOverFrom(this.losts);
    }
    playNewDelivery() {
        this.playVoiceOverFrom(this.newDeliveries);
    }
    playPickup() {
        this.playVoiceOverFrom(this.pickUps);
    }
    playRightPlaceNoPackage() {
        this.playVoiceOver(this.rightPlaceNoPackage);
    }
    playWrongPlace() {
        this.playVoiceOver(this.wrongPlace);
    }
}
exports.AudioEngine = AudioEngine;
const loadSound = (assetsManager, name, url, scene) => __awaiter(void 0, void 0, void 0, function* () {
    const task = assetsManager.addBinaryFileTask(`${name} task`, url);
    const data = yield new Promise((resolve, reject) => {
        task.onSuccess = () => {
            resolve(task.data);
        };
        task.onError = (_task, _message, exception) => {
            reject(exception);
        };
    });
    const sound = yield new Promise((resolve) => {
        const newSound = new sound_1.Sound(name, data, scene, () => {
            resolve(newSound);
        });
    });
    return [name, sound];
});
const load = (assetsManager, scene) => __awaiter(void 0, void 0, void 0, function* () {
    const alertName = 'alert';
    const brakesName = 'brakes';
    const crashName = 'crash';
    const delivered1Name = 'delivered-1';
    const delivered2Name = 'delivered-2';
    const delivered3Name = 'delivered-3';
    const drop1Name = 'drop-1';
    const drop2Name = 'drop-2';
    const drop3Name = 'drop-3';
    const engineName = 'engine';
    const fanfareName = 'fanfair';
    const gameoverName = 'gameover';
    const lost1Name = 'lost-1';
    const lost2Name = 'lost-2';
    const hornName = 'horn';
    const musicName = 'music';
    const newDelivery1Name = 'new-delivery-1';
    const pickUp1Name = 'pick-up-1';
    const pickUp2Name = 'pick-up-2';
    const pickUp3Name = 'pick-up-3';
    const popName = 'pop';
    const rightPlaceNoPackageName = 'right-place-no-package';
    const wahName = 'wah';
    const wrongPlaceName = 'wrong-place';
    const loadSoundArgs = [
        [alertName, alert_mp3_1.default],
        [brakesName, brakes_mp3_1.default],
        [crashName, crash_mp3_1.default],
        [delivered1Name, delivered_1_mp3_1.default],
        [delivered2Name, delivered_2_mp3_1.default],
        [delivered3Name, delivered_3_mp3_1.default],
        [drop1Name, drop_1_mp3_1.default],
        [drop2Name, drop_2_mp3_1.default],
        [drop3Name, drop_3_mp3_1.default],
        [lost1Name, lost_1_mp3_1.default],
        [lost2Name, lost_2_mp3_1.default],
        [engineName, engine_mp3_1.default],
        [fanfareName, fanfare_mp3_1.default],
        [gameoverName, gameover_mp3_1.default],
        [hornName, horn_mp3_1.default],
        [musicName, music_mp3_1.default],
        [newDelivery1Name, new_1_mp3_1.default],
        [pickUp1Name, pick_up_1_mp3_1.default],
        [pickUp2Name, pick_up_2_mp3_1.default],
        [pickUp3Name, pick_up_3_mp3_1.default],
        [popName, pop_mp3_1.default],
        [rightPlaceNoPackageName, right_place_no_package_mp3_1.default],
        [wrongPlaceName, wrong_place_mp3_1.default],
        [wahName, wah_mp3_1.default],
    ];
    const promises = loadSoundArgs.map(([name, url]) => (loadSound(assetsManager, name, url, scene)));
    const sounds = new Map();
    (yield Promise.all(promises)).forEach(([name, sound]) => {
        sounds.set(name, sound);
    });
    const get = (name) => {
        const sound = sounds.get(name);
        if (sound instanceof sound_1.Sound) {
            return sound;
        }
        throw new Error(`no sound for name ${JSON.stringify(name)}`);
    };
    return new AudioEngine({
        alert: get(alertName),
        brakes: get(brakesName),
        crash: get(crashName),
        delivered1: get(delivered1Name),
        delivered2: get(delivered2Name),
        delivered3: get(delivered3Name),
        drop1: get(drop1Name),
        drop2: get(drop2Name),
        drop3: get(drop3Name),
        engine: get(engineName),
        fanfare: get(fanfareName),
        gameover: get(gameoverName),
        horn: get(hornName),
        lost1: get(lost1Name),
        lost2: get(lost2Name),
        music: get(musicName),
        newDelivery1: get(newDelivery1Name),
        pickUp1: get(pickUp1Name),
        pickUp2: get(pickUp2Name),
        pickUp3: get(pickUp3Name),
        pop: get(popName),
        rightPlaceNoPackage: get(rightPlaceNoPackageName),
        wrongPlace: get(wrongPlaceName),
        wah: get(wahName),
    });
});
exports.load = load;
