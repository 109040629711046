"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Goal = void 0;
const standardMaterial_1 = require("@babylonjs/core/Materials/standardMaterial");
const pbrMaterial_1 = require("@babylonjs/core/Materials/PBR/pbrMaterial");
const math_color_1 = require("@babylonjs/core/Maths/math.color");
const SpeechBubbles_1 = require("./SpeechBubbles");
const HighlightCone_1 = require("./HighlightCone");
class Goal {
    constructor(name, mesh, load) {
        this.name = name;
        this.mesh = mesh;
        this.load = load;
        this.isCurrent = false;
        this.isHighlighted = false;
        this.highlightColor = new math_color_1.Color3(0, 255, 0);
        this.completedAt = 0;
        const { material } = this.mesh;
        if (!(material instanceof pbrMaterial_1.PBRMaterial)
            && !(material instanceof standardMaterial_1.StandardMaterial)) {
            throw new Error('Not PBRMaterial');
        }
        this.mesh.material = material.clone('copy');
        this.originalColor = material.emissiveColor;
        this.speechBubbles = new SpeechBubbles_1.SpeechBubbles(this.mesh);
        this.speechBubbles.setText('TARGET', null);
        this.speechBubbles.mesh.position.y -= this.mesh.getBoundingInfo().maximum.y;
        this.speechBubbles.mesh.scaling.set(-1, -1, -1);
        this.highlightCone = new HighlightCone_1.HighlightCone(this.speechBubbles.mesh);
        this.speechBubbles.mesh.isVisible = false;
        this.highlightCone.mesh.isVisible = false;
    }
    setHighlight(highlighted) {
        this.isHighlighted = highlighted;
        this.speechBubbles.mesh.isVisible = highlighted;
        this.highlightCone.mesh.isVisible = highlighted;
        this.updateMaterial();
    }
    updateMaterial() {
        const { material } = this.mesh;
        if (!(material instanceof pbrMaterial_1.PBRMaterial)
            && !(material instanceof standardMaterial_1.StandardMaterial)) {
            throw new Error('Not PBRMaterial');
        }
        if (this.isCurrent && this.isHighlighted) {
            material.emissiveColor = this.highlightColor;
        }
        else {
            material.emissiveColor = this.originalColor;
        }
    }
    getIsCurrent() {
        return this.isCurrent;
    }
    markCompleted() {
        this.completedAt = Date.now();
    }
    getCompletedAt() {
        return this.completedAt;
    }
    getName() {
        return this.name;
    }
    getMesh() {
        return this.mesh;
    }
    setIsCurrent(isCurrent) {
        this.isCurrent = isCurrent;
        if (!isCurrent) {
            this.setHighlight(false);
        }
        this.updateMaterial();
    }
}
exports.Goal = Goal;
