"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Car = void 0;
const math_axis_1 = require("@babylonjs/core/Maths/math.axis");
const csg_1 = require("@babylonjs/core/Meshes/csg");
const math_color_1 = require("@babylonjs/core/Maths/math.color");
const mesh_1 = require("@babylonjs/core/Meshes/mesh");
const meshBuilder_1 = require("@babylonjs/core/Meshes/meshBuilder");
const math_1 = require("@babylonjs/core/Maths/math");
const standardMaterial_1 = require("@babylonjs/core/Materials/standardMaterial");
const texture_1 = require("@babylonjs/core/Materials/Textures/texture");
const trucker_png_1 = require("./assets/textures/trucker.png");
const SpeechBubbles_1 = require("./SpeechBubbles");
const util_1 = require("./util");
const carMeshCache = new Map();
const getChassisKey = (chassisWidth, chassisLength, chassisHeight) => (`${chassisWidth}x${chassisHeight}x${chassisLength}`);
let carMeshCount = 0;
const getCarFromMeshOrCreate = ({ chassisWidth, chassisHeight, chassisLength }, scene) => {
    carMeshCount += 1;
    const key = getChassisKey(chassisWidth, chassisHeight, chassisLength);
    const name = `chassis-${carMeshCount}-${key}`;
    if (carMeshCache.has(key)) {
        return carMeshCache.get(key).createInstance(name);
    }
    const mesh = meshBuilder_1.MeshBuilder.CreateBox(`base-chassis-${key}`, {
        width: chassisWidth,
        depth: chassisLength,
        height: chassisHeight,
    }, scene);
    // XXX: does it need a new rotation
    mesh.isVisible = false;
    mesh.material = new standardMaterial_1.StandardMaterial(name, scene);
    mesh.material.freeze();
    carMeshCache.set(key, mesh);
    return mesh.createInstance(name);
};
const materialCache = new Map();
const getMaterial = (color, scene) => {
    const key = color.toHexString();
    if (materialCache.has(key)) {
        return materialCache.get(key);
    }
    const material = new standardMaterial_1.StandardMaterial(`car-mat-${carMeshCount}`, scene);
    material.diffuseColor = color;
    material.freeze();
    materialCache.set(key, material);
    return material;
};
const wheelMeshCache = new Map();
const wheelMeshCount = 0;
const getWheelMeshOrCreate = (radius, width, scene) => {
    const key = `${radius}x${width}`;
    const name = `wheel-${wheelMeshCount}-${key}`;
    if (wheelMeshCache.has(key)) {
        return wheelMeshCache.get(key).createInstance(name);
    }
    const mesh = meshBuilder_1.MeshBuilder.CreateCylinder(`base-wheel-${key}`, {
        diameter: radius * 2,
        height: width,
        tessellation: 6,
    }, scene);
    mesh.isVisible = false;
    mesh.material = getMaterial(new math_color_1.Color3(0.01, 0, 0.2), scene);
    mesh.rotationQuaternion = new math_1.Quaternion();
    wheelMeshCache.set(key, mesh);
    return mesh.createInstance(name);
};
const createChassisBlock = (size, offset, scene) => {
    const block = meshBuilder_1.MeshBuilder.CreateBox('chassisBack', {
        width: size.x,
        height: size.y,
        depth: size.z,
    }, scene);
    block.position.copyFrom(offset);
    return block;
};
const carCabCache = new Map();
class Car {
    constructor(position, rotation, options, audioEngine, scene, ammo) {
        this.audioEngine = audioEngine;
        this.ammo = ammo;
        this.wheelAxisHeightBack = 0.4;
        this.wheelAxisHeightFront = 0.4;
        this.suspensionStiffness = 10;
        this.suspensionDamping = 0.3;
        this.suspensionCompression = 4.4;
        this.suspensionRestLength = 0.6;
        this.steeringIncrement = 0.01;
        this.steeringClamp = 0.4;
        this.FRONT_LEFT = 0;
        this.FRONT_RIGHT = 1;
        this.BACK_LEFT = 2;
        this.BACK_RIGHT = 3;
        this.maxSpeedRatio = 1;
        this.vehicleSteering = 0;
        this.actions = {
            accelerate: false,
            brake: false,
            right: false,
            left: false,
            horn: false,
        };
        this.wheelMeshes = [];
        this.tmpContactCallbackResult = false;
        this.collidables = new Map();
        this.isUpsideDown = false;
        this.lastUprightChange = Date.now();
        this.isColliding = false;
        this.tmpVector = new math_1.Vector3();
        this.createCab = ({ chassisWidth, chassisLength, chassisHeight, cabColor, }, size, offset, wallThickness, chassisOffsets, scene) => {
            const key = `${getChassisKey(chassisWidth, chassisLength, chassisHeight)}-${cabColor.toHexString()}`;
            const name = `cab-${carMeshCount}-${key}`;
            if (carCabCache.has(key)) {
                return carCabCache.get(key).createInstance(name);
            }
            const chassisBlocks = [];
            chassisOffsets.forEach((cO) => {
                chassisBlocks.push(createChassisBlock(cO.size, cO.offset, this.scene));
            });
            const cabSolid = meshBuilder_1.MeshBuilder.CreateBox('cabSolid', {
                width: size.x,
                height: size.y,
                depth: size.z,
            }, scene);
            const cabHole = meshBuilder_1.MeshBuilder.CreateBox('cabSolid', {
                width: size.x - wallThickness,
                height: size.y - wallThickness,
                depth: size.z - wallThickness,
            }, scene);
            const cabWindscreenHoleX = meshBuilder_1.MeshBuilder.CreateBox('cabHhole', {
                width: size.x,
                height: size.y / 4,
                depth: size.z - wallThickness,
            }, scene);
            const cabWindscreenHoleZ = meshBuilder_1.MeshBuilder.CreateBox('cabHhole', {
                width: size.x - wallThickness,
                height: size.y / 4,
                depth: size.z,
            }, scene);
            cabWindscreenHoleX.position.y += size.y / 4;
            cabWindscreenHoleZ.position.y = cabWindscreenHoleX.position.y;
            let cabSolidCsg = csg_1.CSG.FromMesh(cabSolid);
            chassisBlocks.forEach((c) => {
                c.position.copyFrom(c.position.subtract(offset));
                const csg = csg_1.CSG.FromMesh(c);
                cabSolidCsg = cabSolidCsg.union(csg);
            });
            const cabWindscreenCsg = csg_1.CSG.FromMesh(cabWindscreenHoleX)
                .union(csg_1.CSG.FromMesh(cabWindscreenHoleZ));
            const cabHoleCsg = csg_1.CSG.FromMesh(cabHole);
            const cabCsg = cabSolidCsg.subtract(cabWindscreenCsg).subtract(cabHoleCsg);
            const block = cabCsg.toMesh('cab', this.cabMaterial, this.scene);
            cabSolid.dispose();
            cabHole.dispose();
            cabWindscreenHoleX.dispose();
            cabWindscreenHoleZ.dispose();
            chassisBlocks.forEach((c) => {
                c.isVisible = false;
                c.dispose();
            });
            block.position.copyFrom(offset);
            block.isVisible = false;
            block.material = getMaterial(cabColor, scene);
            carMeshCache.set(key, block);
            return block.createInstance(name);
        };
        this.chassisWidth = options.chassisWidth;
        this.chassisHeight = options.chassisHeight;
        this.chassisLength = options.chassisLength;
        this.massVehicle = options.massVehicle;
        this.maxEngineForce = (0, util_1.ifUndefined)(options.maxEngineForce, 500);
        this.maxBreakingForce = (0, util_1.ifUndefined)(options.maxBreakingForce, 10 * 3);
        this.rollInfluence = (0, util_1.ifUndefined)(options.rollInfluence, 0);
        this.wheelHalfTrackBack = this.chassisWidth / 2;
        this.wheelHalfTrackFront = this.chassisWidth / 2;
        this.wheelWidthBack = this.chassisHeight * 0.6;
        this.wheelWidthFront = this.wheelWidthBack;
        this.wheelRadiusBack = this.chassisHeight * 0.7;
        this.wheelRadiusFront = this.wheelRadiusBack;
        this.wheelAxisFrontPosition = this.chassisLength / 4;
        this.wheelAxisPositionBack = -this.wheelAxisFrontPosition;
        this.scene = scene;
        this.tmpCollisionCallback = new this.ammo.ConcreteContactResultCallback();
        this.tmpCollisionCallback.addSingleResult = () => {
            this.tmpContactCallbackResult = true;
            return 0;
        };
        this.wheelDirectionCS0 = new this.ammo.btVector3(0, -1, 0);
        this.wheelAxleCS = new this.ammo.btVector3(-1, 0, 0);
        const transform = new this.ammo.btTransform();
        transform.setIdentity();
        transform.setOrigin(new this.ammo.btVector3(position.x, position.y, position.z));
        transform.setRotation(new this.ammo.btQuaternion(rotation.x, rotation.y, rotation.z, rotation.w));
        const localInertia = new this.ammo.btVector3(0, 0, 0);
        const geometry = new this.ammo.btBoxShape(new this.ammo.btVector3(this.chassisWidth * 0.5, this.chassisHeight * 0.5, this.chassisLength * 0.5));
        this.cabMaterial = getMaterial(options.cabColor, this.scene);
        this.mesh = getCarFromMeshOrCreate(options, this.scene);
        this.audioEngine.attatchEngineToMesh(this.mesh);
        const chassisOffset = new math_1.Vector3(0, 0.4, 0);
        this.mesh.position.copyFrom(chassisOffset);
        const massOffset = new this.ammo.btVector3(chassisOffset.x, chassisOffset.y, chassisOffset.z);
        this.transformNode = new mesh_1.Mesh('vehicleParent', this.scene);
        this.transformNode.position = new math_1.Vector3();
        this.transformNode.rotationQuaternion = new math_1.Quaternion();
        this.mesh.parent = this.transformNode;
        const transform2 = new this.ammo.btTransform();
        transform2.setIdentity();
        transform2.setOrigin(massOffset);
        const compound = new this.ammo.btCompoundShape();
        compound.addChildShape(transform2, geometry);
        const wallHeight = 0.6;
        const wallThickness = 0.2;
        const chassisOffsets = [
            {
                size: new math_1.Vector3(wallThickness, wallHeight, this.chassisLength),
                offset: new math_1.Vector3(this.chassisWidth / 2 - wallThickness / 2, this.chassisHeight / 2 + wallHeight / 2, 0).add(chassisOffset),
            },
            {
                size: new math_1.Vector3(wallThickness, wallHeight, this.chassisLength),
                offset: new math_1.Vector3(-this.chassisWidth / 2 + wallThickness / 2, this.chassisHeight / 2 + wallHeight / 2, 0).add(chassisOffset),
            },
            {
                size: new math_1.Vector3(this.chassisWidth, wallHeight, wallThickness),
                offset: new math_1.Vector3(0, this.chassisHeight / 2 + wallHeight / 2, -this.chassisLength / 2 + wallThickness / 2).add(chassisOffset),
            },
        ];
        const cabWidth = this.chassisWidth + 0.2;
        const cabHeight = this.chassisHeight * 4;
        const cabThickness = 2;
        // Front
        this.addCabBlock(options, new math_1.Vector3(cabWidth, cabHeight, cabThickness), new math_1.Vector3(0, cabHeight / 2 - this.chassisHeight / 2 - 0.1, this.chassisLength / 2 - cabThickness / 4).add(chassisOffset), wallThickness, chassisOffsets, compound, this.mesh);
        // Driver
        const backUVs = new math_1.Vector4(0, 0, 1, 0.5);
        const frontUVs = new math_1.Vector4(0, 0.5, 1, 1);
        const plane = meshBuilder_1.MeshBuilder.CreatePlane('driver', {
            height: 1,
            width: 1,
            sideOrientation: mesh_1.Mesh.DOUBLESIDE,
            frontUVs,
            backUVs,
        }, this.scene);
        plane.position = new math_1.Vector3(0, cabHeight / 2, this.chassisLength / 2 - cabThickness / 4).add(chassisOffset);
        const driverMaterial = new standardMaterial_1.StandardMaterial('driver', this.scene);
        plane.material = driverMaterial;
        driverMaterial.diffuseTexture = new texture_1.Texture(trucker_png_1.default, this.scene);
        driverMaterial.diffuseTexture.hasAlpha = true;
        driverMaterial.freeze();
        plane.setParent(this.mesh);
        compound.calculateLocalInertia(this.massVehicle, localInertia);
        const motionState = new this.ammo.btDefaultMotionState(transform);
        this.body = new this.ammo.btRigidBody(new this.ammo.btRigidBodyConstructionInfo(this.massVehicle, motionState, compound, localInertia));
        this.body.setActivationState(4);
        const physicsWorld = scene.getPhysicsEngine().getPhysicsPlugin().world;
        physicsWorld.addRigidBody(this.body);
        this.tuning = new this.ammo.btVehicleTuning();
        const rayCaster = new this.ammo.btDefaultVehicleRaycaster(physicsWorld);
        this.vehicle = new this.ammo.btRaycastVehicle(this.tuning, this.body, rayCaster);
        this.vehicle.setCoordinateSystem(0, 1, 2);
        physicsWorld.addAction(this.vehicle);
        this.addWheel(true, new this.ammo.btVector3(this.wheelHalfTrackFront, this.wheelAxisHeightFront, this.wheelAxisFrontPosition), this.wheelRadiusFront, this.wheelWidthFront, this.FRONT_LEFT);
        this.addWheel(true, new this.ammo.btVector3(-this.wheelHalfTrackFront, this.wheelAxisHeightFront, this.wheelAxisFrontPosition), this.wheelRadiusFront, this.wheelWidthFront, this.FRONT_RIGHT);
        this.addWheel(false, new this.ammo.btVector3(-this.wheelHalfTrackBack, this.wheelAxisHeightBack, this.wheelAxisPositionBack), this.wheelRadiusBack, this.wheelWidthBack, this.BACK_LEFT);
        this.addWheel(false, new this.ammo.btVector3(this.wheelHalfTrackBack, this.wheelAxisHeightBack, this.wheelAxisPositionBack), this.wheelRadiusBack, this.wheelWidthBack, this.BACK_RIGHT);
        this.initAudio();
    }
    // eslint-disable-next-line class-methods-use-this
    initAudio() { }
    // eslint-disable-next-line class-methods-use-this
    disposeAudio() { }
    dispose() {
        this.disposeAudio();
    }
    update() {
        const speed = this.vehicle.getCurrentSpeedKmHour();
        let breakingForce = 0;
        let engineForce = 0;
        if (this.actions.accelerate) {
            if (speed < -1) {
                breakingForce = this.maxBreakingForce;
            }
            else {
                engineForce = this.maxEngineForce;
            }
        }
        else if (this.actions.brake) {
            if (speed > 1) {
                breakingForce = this.maxBreakingForce;
            }
            else {
                engineForce = -this.maxEngineForce;
            }
        }
        const absSpeed = Math.abs(speed);
        if (absSpeed > 100 * this.maxSpeedRatio) {
            engineForce = 0;
        }
        if (this.actions.right) {
            if (this.vehicleSteering < this.steeringClamp) {
                this.vehicleSteering += this.steeringIncrement;
            }
        }
        else if (this.actions.left) {
            if (this.vehicleSteering > -this.steeringClamp) {
                this.vehicleSteering -= this.steeringIncrement;
            }
        }
        else {
            this.vehicleSteering = 0;
        }
        this.vehicle.applyEngineForce(engineForce, this.FRONT_LEFT);
        this.vehicle.applyEngineForce(engineForce, this.FRONT_RIGHT);
        this.vehicle.setBrake(breakingForce / 2, this.FRONT_LEFT);
        this.vehicle.setBrake(breakingForce / 2, this.FRONT_RIGHT);
        this.vehicle.setBrake(breakingForce, this.BACK_LEFT);
        this.vehicle.setBrake(breakingForce, this.BACK_RIGHT);
        this.vehicle.setSteeringValue(this.vehicleSteering, this.FRONT_LEFT);
        this.vehicle.setSteeringValue(this.vehicleSteering, this.FRONT_RIGHT);
        const n = this.vehicle.getNumWheels();
        for (let i = 0; i < n; i += 1) {
            this.vehicle.updateWheelTransform(i, true);
            const tm = this.vehicle.getWheelTransformWS(i);
            const p = tm.getOrigin();
            const q = tm.getRotation();
            this.wheelMeshes[i].position.set(p.x(), p.y(), p.z());
            this.wheelMeshes[i].rotationQuaternion.set(q.x(), q.y(), q.z(), q.w());
            this.wheelMeshes[i].rotate(math_axis_1.Axis.Z, Math.PI / 2);
        }
        const tm = this.vehicle.getChassisWorldTransform();
        const p = tm.getOrigin();
        const q = tm.getRotation();
        this.transformNode.position.set(p.x(), p.y(), p.z());
        this.transformNode.rotationQuaternion.set(q.x(), q.y(), q.z(), q.w());
        const v = this.tmpVector;
        v.copyFrom(math_1.Vector3.Down());
        const dot = math_1.Vector3.Dot(this.transformNode.up, v);
        // Number found from experimentally looking at trucks on their side
        const isUpsideDown = dot > -0.1;
        if (this.isUpsideDown !== isUpsideDown) {
            this.lastUprightChange = Date.now();
        }
        this.isUpsideDown = isUpsideDown;
        const physicsWorld = this.scene.getPhysicsEngine().getPhysicsPlugin().world;
        this.tmpContactCallbackResult = false;
        physicsWorld.contactTest(this.body, this.tmpCollisionCallback);
        if (this.tmpContactCallbackResult) {
            for (const c of this.collidables.keys()) {
                this.tmpContactCallbackResult = false;
                physicsWorld.contactPairTest(this.body, c, this.tmpCollisionCallback);
                if (this.tmpContactCallbackResult) {
                    this.collidables.get(c)();
                }
            }
        }
        this.updateAudio();
    }
    // eslint-disable-next-line class-methods-use-this
    updateAudio() {
    }
    setMaxSpeedRatio(maxSpeedRatio) {
        this.maxSpeedRatio = maxSpeedRatio;
    }
    addCollidable(body, callback) {
        this.collidables.set(body, callback);
    }
    reset(position, rotation) {
        this.resetFlip(rotation, position);
    }
    resetFlipCurrent() {
        const rotation = this.transformNode.rotationQuaternion.clone();
        const euler = rotation.toEulerAngles('YXZ');
        euler.x = 0;
        euler.z = 0;
        this.resetFlip(euler.toQuaternion());
    }
    resetFlip(rotation, position) {
        const trans = this.body.getWorldTransform();
        if (position !== undefined) {
            trans.setIdentity();
            trans.setOrigin(new this.ammo.btVector3(position.x, position.y, position.z));
        }
        trans.setRotation(new this.ammo.btQuaternion(rotation.x, rotation.y, rotation.z, rotation.w));
        this.body.setLinearVelocity(new this.ammo.btVector3(0, 0, 0));
        this.body.setAngularVelocity(new this.ammo.btVector3(0, 0, 0));
    }
    addCabBlock(options, size, offset, wallThickness, chassisOffsets, compound, meshParent) {
        const cab = this.createCab(options, size, offset, wallThickness, chassisOffsets, this.scene);
        meshParent.addChild(cab);
        this.addCompoundChild(size, offset, compound);
        chassisOffsets.forEach((cO) => {
            this.addCompoundChild(cO.size, cO.offset, compound);
        });
        this.speechBubbles = new SpeechBubbles_1.SpeechBubbles(meshParent);
    }
    addCompoundChild(size, offset, compound) {
        const blockGeometry = new this.ammo.btBoxShape(new this.ammo.btVector3(size.x * 0.5, size.y * 0.5, size.z * 0.5));
        const transform = new this.ammo.btTransform();
        transform.setIdentity();
        transform.setOrigin(new this.ammo.btVector3(offset.x, offset.y, offset.z));
        compound.addChildShape(transform, blockGeometry);
    }
    addWheel(isFront, pos, radius, width, index) {
        const wheelInfo = this.vehicle.addWheel(pos, this.wheelDirectionCS0, this.wheelAxleCS, this.suspensionRestLength, radius, this.tuning, isFront);
        wheelInfo.set_m_suspensionStiffness(this.suspensionStiffness);
        wheelInfo.set_m_wheelsDampingRelaxation(this.suspensionDamping);
        wheelInfo.set_m_wheelsDampingCompression(this.suspensionCompression);
        wheelInfo.set_m_maxSuspensionForce(600000);
        wheelInfo.set_m_frictionSlip(40);
        wheelInfo.set_m_rollInfluence(this.rollInfluence);
        this.wheelMeshes[index] = this.createWheelMesh(radius, width);
    }
    createWheelMesh(radius, width) {
        return getWheelMeshOrCreate(radius, width, this.scene);
    }
}
exports.Car = Car;
